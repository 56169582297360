// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/AboutUs.vue';
import Blog from '../views/blog.vue';
import Contact from '../views/contact.vue';
import IQtest from '../views/iqteest.vue';
import Faq from "../views/Faq";
import Blogpost from "../views/blogpost.vue";
import Termsandcondition from "../views/termsandcondition.vue";
import Privacypolicy from "../views/privacypolicy.vue";
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About Us',
    component: About,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: Faq,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/test',
    name: 'IQtest',
    component: IQtest,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/blogpost/:id',
    name: 'Blog-Post',
    component: Blogpost,
  },
  {
    path: '/termsandcondition',
    name: 'Terms-And-Condition',
    component: Termsandcondition,
  },
  {
    path: '/privacypolicy',
    name: 'Privacy-Policy',
    component: Privacypolicy,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
    <Webheader />
    <section>
      <div class="blogpost">
        <component :is="currentComponent" />
      </div>
    </section>
    <Footers />
  </template>
  
  <script>
  import { useRoute } from "vue-router";
  import { computed } from "vue"; // Import computed
  import Webheader from "../components/Webheader.vue";
  import Footers from "../components/Footer.vue";
  import Blogcomp from "../components/blogcomp.vue";
  import Blogcomp1 from "../components/blogcomp1.vue";
  import Blogcomp2 from "../components/blogcomp2.vue";
  
  export default {
    name: "App",
    components: {
      Webheader,
      Footers,
      Blogcomp,
      Blogcomp1,
      Blogcomp2
    },
    setup() {
      const route = useRoute(); // Get the current route
      const currentComponent = computed(() => {
        const id = route.params.id;
        if (id === "1") {
          return "Blogcomp";
        } 
        else if (id === "2") {
          return "Blogcomp1";
        }
        else if (id === "3") {
          return "Blogcomp2";
        }
        return null; // Or handle default case
      });
  
      return { currentComponent };
    },
  };
  </script>
  <style scoped>
   @font-face {
    font-family: BalooBhai2;
    src: url("../assets/baloo-bhai-2/BalooBhai2-Regular.ttf");
  }

  *{
    font-family: BalooBhai2 !important;
  }
    .blogpost{
        width: 65%;
        display: block;
        margin: 0 auto;
        margin-top: 10%;
        margin-bottom: 8%;
    }
    @media screen and (max-width:600px){
        .blogpost{
          margin-top: 15%;
          width: 95%;
        }
    }
</style>
<template>
  <div class="staco-overly-bg"></div>
  <Webheader />
  <section>
    <div style="width: 85%;
      margin: 0 auto;
      margin-top: 8%;margin-bottom: 5%;max-width: 1300px;" class="e-con-inner media-about">
      <div class="elementor-element elementor-element-d8f6898 elementor-widget elementor-widget-heading"
        data-id="d8f6898" data-element_type="widget" data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">About US
          </h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-1a42e90 elementor-widget elementor-widget-text-editor"
        data-id="1a42e90" data-element_type="widget" data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <p>At IQspark.org, we’re passionate about exploring the fascinating realm of human intelligence. As computer
            science graduates with a shared enthusiasm for understanding the complexities of the human mind, we embarked
            on a journey to create a platform that celebrates intelligence in all its forms.</p>
        </div>
      </div>
      <div class="elementor-element elementor-element-e64e319 elementor-widget elementor-widget-heading"
        data-id="e64e319" data-element_type="widget" data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Our Mission</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-06fd9e0 elementor-widget elementor-widget-text-editor"
        data-id="06fd9e0" data-element_type="widget" data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <p>Our mission at IQspark.org is simple yet profound: to provide a welcoming space where individuals from all
            walks of life can engage with IQ testing in a fun, informative, and meaningful way. We believe that
            intelligence is not just about numbers or scores; it’s about curiosity, creativity, and the relentless
            pursuit of knowledge.</p>
        </div>
      </div>
      <div class="elementor-element elementor-element-3d2de49 elementor-widget elementor-widget-heading"
        data-id="3d2de49" data-element_type="widget" data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">What We Offer</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-f5cbca1 elementor-widget elementor-widget-text-editor"
        data-id="f5cbca1" data-element_type="widget" data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <p>IQspark.org offers a comprehensive suite of IQ testing resources, designed to cater to a diverse audience.
            Whether you’re a student looking to gauge your cognitive abilities, a professional seeking personal
            development, or simply curious about the fascinating world of IQ testing, our platform has something for
            everyone.</p>
        </div>
      </div>
      <div class="elementor-element elementor-element-1192e0c elementor-widget elementor-widget-heading"
        data-id="1192e0c" data-element_type="widget" data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Why Choose IQspark.org?</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-a9b0e91 elementor-widget elementor-widget-text-editor"
        data-id="a9b0e91" data-element_type="widget" data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <p>What sets IQspark.org apart is our commitment to accessibility, accuracy, and innovation. We’ve crafted our
            tests with meticulous attention to detail, drawing upon the latest research in cognitive psychology and
            psychometrics to ensure reliability and validity. Our user-friendly interface makes navigating our platform
            a breeze, while our engaging content keeps you coming back for more.</p>
        </div>
      </div>
      <div class="elementor-element elementor-element-26b1b55 elementor-widget elementor-widget-heading"
        data-id="26b1b55" data-element_type="widget" data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Join Our Community</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-f8049b4 elementor-widget elementor-widget-text-editor"
        data-id="f8049b4" data-element_type="widget" data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <p>At IQspark.org, we believe in the power of community. Join our growing network of like-minded individuals
            who share a passion for intellectual exploration. Connect with fellow test-takers, exchange insights and
            experiences, and embark on a journey of self-discovery like never before.</p>
        </div>
      </div>
      <div class="elementor-element elementor-element-e440959 elementor-widget elementor-widget-heading"
        data-id="e440959" data-element_type="widget" data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Get in Touch</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-b9fd3f2 elementor-widget elementor-widget-text-editor"
        data-id="b9fd3f2" data-element_type="widget" data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <p>Have questions, feedback, or suggestions? We’d love to hear from you! Get in touch with our team at
            support@iqspark.org and let us know how we can make your IQspark.org experience even better.</p>
        </div>
      </div>
      <div class="elementor-element elementor-element-6ab87d1 elementor-widget elementor-widget-heading"
        data-id="6ab87d1" data-element_type="widget" data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Start Your Journey</h2>
        </div>
      </div>
      <div class="elementor-element elementor-element-40c6a13 elementor-widget elementor-widget-text-editor"
        data-id="40c6a13" data-element_type="widget" data-widget_type="text-editor.default">
        <div class="elementor-widget-container">
          <p>Ready to unlock your potential? Dive into the world of IQ testing with IQspark.org today and ignite your
            curiosity, spark your intellect, and unleash your brilliance!</p>
        </div>
      </div>
    </div>
  </section>
  <Footers />
</template>
<script>
  import Webheader from '../components/Webheader.vue';
  import Footers from '../components/Footer.vue';
  export default {
    name: 'App',

    // Register the component
    components: {
      Webheader,
      Footers
    },
  };
</script>
<style scoped>
@media screen and (max-width:500px){
  .media-about{
    margin-top: 100px !important;
  }
}
 @font-face {
    font-family: BalooBhai2;
    src: url("../assets/baloo-bhai-2/BalooBhai2-Regular.ttf");
  }

  *{
    font-family: BalooBhai2 !important;
  }
  /*! CSS Used from: https://iqspark.org/wp-content/themes/astra/assets/css/minified/main.min.css?ver=4.8.1 ; media=all */
  @media all {

    h2,
    p {
      border: 0;
      font-size: 100%;
      font-style: inherit;
      font-weight: inherit;
      margin: 0;
      outline: 0;
      padding: 0;
      vertical-align: baseline;
    }

    h2 {
      clear: both;
    }

    .entry-content h2,
    h2 {
      color: #808285;
      font-size: 1.7em;
      line-height: 1.3;
    }

    *,
    :after,
    :before {
      box-sizing: inherit;
    }

    ::selection {
  background-color: #007bff; /* Use a blue color for the selection background */
  color: #fff; /* Use white for the selected text color */
}
    .entry-content h2 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 1.75em;
    }

    .entry-content p {
      margin-bottom: 1.6em;
    }
  }

  /*! CSS Used from: Embedded */
  h2,
  .entry-content h2 {
    font-weight: 600;
  }

  h2,
  .entry-content h2 {
    font-size: 30px;
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 1.3em;
  }

  ::selection {
  background-color: #007bff; /* Use a blue color for the selection background */
  color: #fff; /* Use white for the selected text color */
}

  h2,
  .entry-content h2 {
    color: var(--ast-global-color-3);
  }

  ::placeholder {
    color: var(--ast-form-field-color, #9CA3AF);
  }

  .elementor-widget-heading h2.elementor-heading-title {
    line-height: 1.3em;
  }

  @media (max-width:921px) {

    h2,
    .entry-content h2 {
      font-size: 25px;
    }
  }

  @media (max-width:544px) {

    h2,
    .entry-content h2 {
      font-size: 25px;
    }
  }

  h2,
  .entry-content h2 {
    color: var(--ast-global-color-2);
  }

  .elementor-widget-heading .elementor-heading-title {
    margin: 0;
  }

  /*! CSS Used from: https://iqspark.org/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.24.4 ; media=all */
  @media all {

    .elementor *,
    .elementor :after,
    .elementor :before {
      box-sizing: border-box;
    }

    .elementor-element {
      --flex-direction: initial;
      --flex-wrap: initial;
      --justify-content: initial;
      --align-items: initial;
      --align-content: initial;
      --gap: initial;
      --flex-basis: initial;
      --flex-grow: initial;
      --flex-shrink: initial;
      --order: initial;
      --align-self: initial;
      flex-basis: var(--flex-basis);
      flex-grow: var(--flex-grow);
      flex-shrink: var(--flex-shrink);
      order: var(--order);
      align-self: var(--align-self);
    }

    .elementor-element:where(.e-con-full, .elementor-widget) {
      flex-direction: var(--flex-direction);
      flex-wrap: var(--flex-wrap);
      justify-content: var(--justify-content);
      align-items: var(--align-items);
      align-content: var(--align-content);
      gap: var(--gap);
    }

    .elementor-widget {
      position: relative;
    }

    .elementor-widget:not(:last-child) {
      margin-bottom: 20px;
    }

    .e-con>.e-con-inner {
      text-align: var(--text-align);
      padding-block-start: var(--padding-block-start);
      padding-block-end: var(--padding-block-end);
    }

    .e-con.e-flex>.e-con-inner {
      flex-direction: var(--flex-direction);
    }

    .e-con>.e-con-inner {
      display: var(--display);
    }

    .e-con.e-flex>.e-con-inner {
      flex-wrap: var(--flex-wrap);
      justify-content: var(--justify-content);
      align-items: var(--align-items);
      align-content: var(--align-content);
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      align-self: auto;
    }

    .e-con>.e-con-inner {
      gap: var(--gap);
      width: 100%;
      max-width: var(--content-width);
      margin: 0 auto;
      padding-inline-start: 0;
      padding-inline-end: 0;
      height: 100%;
    }

    .e-con .elementor-widget.elementor-widget {
      margin-block-end: 0;
    }

    .e-con .elementor-widget {
      min-width: 0;
    }

    .e-con>.e-con-inner>.elementor-widget>.elementor-widget-container {
      height: 100%;
    }

    .e-con.e-con>.e-con-inner>.elementor-widget {
      max-width: 100%;
    }

    .elementor-element .elementor-widget-container {
      transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
    }

    .elementor-heading-title {
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/plugins/elementor/assets/css/conditionals/e-swiper.min.css?ver=3.24.4 ; media=all */
  @media all {
    .elementor-element {
      --swiper-theme-color: #000;
      --swiper-navigation-size: 44px;
      --swiper-pagination-bullet-size: 6px;
      --swiper-pagination-bullet-horizontal-gap: 6px;
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/post-7.css?ver=1722170818 ; media=all */
  @media all {
    .elementor-widget:not(:last-child) {
      margin-block-end: 20px;
    }

    .elementor-element {
      --widgets-spacing: 20px 20px;
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/global.css?ver=1722170819 ; media=all */
  @media all {
    .elementor-widget-heading .elementor-heading-title {
      color: var(--e-global-color-primary);
      font-family: var(--e-global-typography-primary-font-family), Sans-serif;
      font-weight: var(--e-global-typography-primary-font-weight);
    }

    .elementor-widget-text-editor {
      color: var(--e-global-color-text);
      font-family: var(--e-global-typography-text-font-family), Sans-serif;
      font-weight: var(--e-global-typography-text-font-weight);
    }
  }

  /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/post-206.css?ver=1722170821 ; media=all */
  @media all {
    .elementor-206 .elementor-element.elementor-element-d8f6898 .elementor-heading-title {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 35px;
      font-weight: 700;
    }

    .elementor-206 .elementor-element.elementor-element-d8f6898>.elementor-widget-container {
      margin: 0px 0px 10px 0px;
    }

    .elementor-206 .elementor-element.elementor-element-1a42e90 {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }

    .elementor-206 .elementor-element.elementor-element-e64e319 .elementor-heading-title {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 35px;
      font-weight: 700;
    }

    .elementor-206 .elementor-element.elementor-element-e64e319>.elementor-widget-container {
      margin: 0px 0px 10px 0px;
    }

    .elementor-206 .elementor-element.elementor-element-06fd9e0 {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }

    .elementor-206 .elementor-element.elementor-element-3d2de49 .elementor-heading-title {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 35px;
      font-weight: 700;
    }

    .elementor-206 .elementor-element.elementor-element-3d2de49>.elementor-widget-container {
      margin: 0px 0px 10px 0px;
    }

    .elementor-206 .elementor-element.elementor-element-f5cbca1 {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }

    .elementor-206 .elementor-element.elementor-element-1192e0c .elementor-heading-title {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 35px;
      font-weight: 700;
    }

    .elementor-206 .elementor-element.elementor-element-1192e0c>.elementor-widget-container {
      margin: 0px 0px 10px 0px;
    }

    .elementor-206 .elementor-element.elementor-element-a9b0e91 {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }

    .elementor-206 .elementor-element.elementor-element-26b1b55 .elementor-heading-title {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 35px;
      font-weight: 700;
    }

    .elementor-206 .elementor-element.elementor-element-26b1b55>.elementor-widget-container {
      margin: 0px 0px 10px 0px;
    }

    .elementor-206 .elementor-element.elementor-element-f8049b4 {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }

    .elementor-206 .elementor-element.elementor-element-e440959 .elementor-heading-title {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 35px;
      font-weight: 700;
    }

    .elementor-206 .elementor-element.elementor-element-e440959>.elementor-widget-container {
      margin: 0px 0px 10px 0px;
    }

    .elementor-206 .elementor-element.elementor-element-b9fd3f2 {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }

    .elementor-206 .elementor-element.elementor-element-6ab87d1 .elementor-heading-title {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 35px;
      font-weight: 700;
    }

    .elementor-206 .elementor-element.elementor-element-6ab87d1>.elementor-widget-container {
      margin: 0px 0px 10px 0px;
    }

    .elementor-206 .elementor-element.elementor-element-40c6a13 {
      color: #000000;
      font-family: "Baloo Bhai 2", Sans-serif;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
    }
  }

  /*! CSS Used from: https://iqtester.org/assets/css/bootstrap.min.css */
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  @media (min-width:1200px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: calc(1.325rem + .9vw);
  }

  @media (min-width:1200px) {
    h2 {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: calc(1.3rem + .6vw);
  }

  @media (min-width:1200px) {
    h3 {
      font-size: 1.75rem;
    }
  }

  h4 {
    font-size: calc(1.275rem + .3vw);
  }

  @media (min-width:1200px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul {
    padding-left: 2rem;
  }

  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul ul {
    margin-bottom: 0;
  }

  a {
    color: var(--bs-link-color);
    text-decoration: underline;
  }

  a:hover {
    color: var(--bs-link-hover-color);
  }

  img,
  svg {
    vertical-align: middle;
  }

  button {
    border-radius: 0;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
  }

  button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button {
    text-transform: none;
  }

  [type=button],
  button {
    -webkit-appearance: button;
  }

  [type=button]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }

  .container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width:576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width:768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width:992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width:1200px) {
    .container {
      max-width: 1140px;
    }
  }

  @media (min-width:1400px) {
    .container {
      max-width: 1320px;
    }
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
  }

  .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }

  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  @media (min-width:768px) {
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  @media (min-width:992px) {
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
  }

  @media (min-width:1200px) {
    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
  }

  .collapse:not(.show) {
    display: none;
  }

  .dropdown-toggle {
    white-space: nowrap;
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }

  .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
  }

  .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg);
  }

  .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent;
  }

  .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  }

  @media (prefers-reduced-motion:reduce) {
    .nav-link {
      transition: none;
    }
  }

  .nav-link:focus,
  .nav-link:hover {
    color: var(--bs-nav-link-hover-color);
  }

  .navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0.5rem;
    --bs-navbar-color: rgba(0, 0, 0, 0.55);
    --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
    --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
    --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-padding-y: 0.3125rem;
    --bs-navbar-brand-margin-end: 1rem;
    --bs-navbar-brand-font-size: 1.25rem;
    --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
    --bs-navbar-nav-link-padding-x: 0.5rem;
    --bs-navbar-toggler-padding-y: 0.25rem;
    --bs-navbar-toggler-padding-x: 0.75rem;
    --bs-navbar-toggler-font-size: 1.25rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29'stroke-linecap='round'stroke-miterlimit='10'stroke-width='2'd='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
    --bs-navbar-toggler-border-radius: 0.375rem;
    --bs-navbar-toggler-focus-width: 0.25rem;
    --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  }

  .navbar>.container {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-brand {
    padding-top: var(--bs-navbar-brand-padding-y);
    padding-bottom: var(--bs-navbar-brand-padding-y);
    margin-right: var(--bs-navbar-brand-margin-end);
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap;
  }

  .navbar-brand:focus,
  .navbar-brand:hover {
    color: var(--bs-navbar-brand-hover-color);
  }

  .navbar-nav {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-color: var(--bs-navbar-color);
    --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
    --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  @media (min-width:992px) {
    .navbar-expand-lg {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x);
    }

    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
  }

  .accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0 0 16 16'fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd'd='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0 0 16 16'fill='%230c63e4'%3e%3cpath fill-rule='evenodd'd='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: #86b7fe;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #0c63e4;
    --bs-accordion-active-bg: #e7f1ff;
  }

  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
  }

  @media (prefers-reduced-motion:reduce) {
    .accordion-button {
      transition: none;
    }
  }

  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
  }

  @media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
      transition: none;
    }
  }

  .accordion-button:hover {
    z-index: 2;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  }

  .accordion-header {
    margin-bottom: 0;
  }

  .accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  }

  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius);
  }

  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius);
  }

  .accordion-item:not(:first-of-type) {
    border-top: 0;
  }

  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
  }

  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
  }

  .accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  }

  .progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .order-1 {
    order: 1 !important;
  }

  .order-2 {
    order: 2 !important;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .text-center {
    text-align: center !important;
  }

  @media (min-width:768px) {
    .order-md-1 {
      order: 1 !important;
    }

    .order-md-2 {
      order: 2 !important;
    }
  }

  @media (min-width:992px) {
    .order-lg-1 {
      order: 1 !important;
    }

    .order-lg-2 {
      order: 2 !important;
    }
  }

  /*! CSS Used from: https://iqtester.org/assets/css/slick.css */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  .slick-slide img {
    display: block;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  /*! CSS Used from: https://iqtester.org/assets/css/asPieProgress.min.css */
  .pie_progress {
    position: relative;
    text-align: center;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .pie_progress__svg {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;
    vertical-align: middle;
  }

  .pie_progress__svg svg {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .pie_progress__label {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .pie_progress__label {
    margin-top: 32px;
    font-size: 12px;
  }

  /*! CSS Used from: https://iqtester.org/assets/css/lightbox.css */
  .lightboxOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: black;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8;
    display: none;
  }

  .lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 10000;
    text-align: center;
    line-height: 0;
    font-weight: normal;
    outline: none;
  }

  .lightbox .lb-image {
    display: block;
    height: auto;
    max-width: inherit;
    max-height: none;
    border-radius: 30px;
    border: 4px solid white;
  }

  .lb-outerContainer {
    position: relative;
    /* *zoom: 1; */
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
    border-radius: 30px;
    background-color: white;
  }

  .lb-outerContainer:after {
    content: "";
    display: table;
    clear: both;
  }

  .lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    height: 25%;
    width: 100%;
    text-align: center;
    line-height: 0;
  }

  .lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    background: url(https://iqtester.org/assets/images/portfolio/loading.gif) no-repeat;
  }

  .lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  .lb-nav a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
  }

  .lb-prev,
  .lb-next {
    height: 100%;
    cursor: pointer;
    display: block;
  }

  .lb-nav a.lb-prev {
    width: 34%;
    left: 0;
    float: left;
    background: url(https://iqtester.org/assets/images/portfolio/prev.svg) left 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }

  .lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }

  .lb-nav a.lb-next {
    width: 64%;
    right: 0;
    float: right;
    background: url(https://iqtester.org/assets/images/portfolio/next.svg) right 48% no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.6s;
    -moz-transition: opacity 0.6s;
    -o-transition: opacity 0.6s;
    transition: opacity 0.6s;
  }

  .lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }

  .lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    /* *zoom: 1; */
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .lb-dataContainer:after {
    content: "";
    display: table;
    clear: both;
  }

  .lb-data {
    padding: 0 4px;
    color: #ccc;
  }

  .lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
  }

  .lb-data .lb-caption {
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
  }

  .lb-data .lb-number {
    display: block;
    clear: left;
    padding-bottom: 1em;
    font-size: 12px;
    color: #999999;
  }

  .lb-data .lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    background: url(https://iqtester.org/assets/images/portfolio/close.png) top right no-repeat;
    text-align: right;
    outline: none;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  .lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }

  /*! CSS Used from: https://iqtester.org/assets/css/headline.css */
  *,
  *::after,
  *::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-size: 1.6rem;
    font-family: "Source Sans Pro", sans-serif;
    color: #aebcb9;
    background-color: #0d0d0d;
  }

  a {
    text-decoration: none;
  }

  /*! CSS Used from: https://iqtester.org/assets/css/style.css */
  * {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    color: #444444;
    background-color: #ffffff;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 700;
    letter-spacing: 0;
    color: #111111;
    line-height: 1.46;
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
  }

  h6 {
    font-size: 15px;
    font-weight: 500;
  }

  .wt-700 {
    font-weight: 700 !important;
  }

  *:focus {
    outline: none;
  }

  img {
    max-width: 100%;
  }

  ::selection {
  background-color: #007bff; /* Use a blue color for the selection background */
  color: #fff; /* Use white for the selected text color */
}

  a,
  button {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  a:hover,
  button:hover {
    text-decoration: none;
    color: inherit;
  }

  a:focus,
  button:focus {
    text-decoration: none;
    color: inherit;
    outline: none;
    border: none;
  }

  button {
    cursor: pointer;
  }

  button {
    border-radius: 0;
    border: 0;
    background-color: transparent;
  }

  @media (min-width: 1400px) {
    .container {
      max-width: 1170px;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .white-color {
    color: #ffffff;
  }

  .row {
    margin: 0px -15px !important;
  }

  .col-xl-5,
  .col-xl-6,
  .col-lg-3,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-md-3,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-12,
  .col-6 {
    padding: 0px 15px !important;
  }

  .rotate-icon-btn {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .rotate-icon-btn .rotate-icon {
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .rotate-icon-btn .icon {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .rotate-icon {
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .header-section {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;
  }

  .header-section.v1 .logo-dark {
    display: none;
  }

  .header-section.v1 .main-menu .submenu li a {
    color: #444444;
  }

  .header-section.v1 .main-menu .submenu li a:hover {
    color: #111111;
  }

  .header-section .header-navbar-container {
    padding-left: 0;
    padding-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 60px;
  }

  .header-section .header-logo {
    padding: 0 !important;
    margin-right: 0;
  }

  .header-section .header-navbar-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .header-section .navbar {
    padding: 0px;
  }

  .main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
  }

  .main-menu ul {
    list-style: none;
  }

  .main-menu li {
    position: relative;
  }

  .main-menu li:hover .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .main-menu li:hover .submenu {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
    width: 250px;
  }

  .main-menu li:hover .submenu li a {
    line-height: 333%;
  }

  .main-menu li a {
    color: #ffffff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    padding: 15px 0px;
    font-weight: 500;
  }

  .main-menu li a:hover,
  .main-menu li a:focus {
    color: #ffffffb2;
  }

  .main-menu .dropdown-toggle::after {
    vertical-align: 0;
    border: 0;
    content: url("https://iqtester.org/assets/images/icons/dropdown-toggle.svg");
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    display: none;
  }

  .main-menu .submenu {
    position: absolute;
    top: 120%;
    left: 0;
    width: 180px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 13px 24px;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
  }

  .main-menu .submenu li a {
    line-height: 36px;
    padding: 0px !important;
  }

  .main-menu .submenu li a:hover {
    color: #0095ff;
  }

  .header-extra {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .header-extra li a {
    color: #ffffff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .header-extra li a:hover {
    color: #ffffffb2;
  }

  .nav-expander {
    display: none;
  }

  .nav-expander img:nth-child(1) {
    display: block;
  }

  .nav-expander img:nth-child(2) {
    display: none;
  }

  .right_menu_togle {
    background: #ffffff;
    -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    padding: 70px 0 20px 0;
    width: 100%;
    top: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    z-index: 999;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: fixed;
    overflow-y: auto;
  }

  .mobile-navbar-menu .sub-menu {
    display: none;
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-top: 10px;
  }

  .mobile-navbar-menu .nav-menu {
    width: 720px;
    margin: 0 auto;
    padding: 0 7px;
    padding: 0;
    list-style: none;
  }

  .mobile-navbar-menu .nav-menu li a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    text-transform: capitalize;
    color: #111111;
    line-height: 20px;
    padding: 16px 0;
    width: 100%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-weight: 500;
    font-size: 15px;
  }

  .mobile-navbar-menu .nav-menu li li a {
    font-size: 14px;
    color: #444444;
    line-height: 20px;
    padding: 7px 20px;
  }

  .mobile-navbar-menu .nav-menu li {
    position: relative;
  }

  .mobile-navbar-menu span.submenu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 5px;
    display: block;
    height: 40px;
    width: 40px;
    cursor: pointer;
    width: 100%;
    cursor: pointer;
    top: 0;
    height: 50px;
  }

  .mobile-navbar-menu span.submenu-button::before,
  .mobile-navbar-menu span.submenu-button::after {
    position: absolute;
    top: 24px;
    right: 21px;
    width: 20px;
    height: 2px;
    display: block;
    background: #999999;
    z-index: 2;
    content: "";
    right: 2px;
    width: 12px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .mobile-navbar-menu span.submenu-button::before {
    width: 2px;
    height: 12px;
    right: 26px;
    top: 19px;
  }

  .mobile-navbar-menu span.submenu-button em {
    display: block;
    position: absolute;
    right: 0;
    border-radius: 4px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .mobile-navbar-menu ul ul li,
  .mobile-navbar-menu li:hover>ul>li {
    height: auto;
  }

  .mobile-navbar-menu .nav-buttons {
    width: 720px;
    margin: 20px auto 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    padding: 0 7px;
    list-style: none;
  }

  .mobile-navbar-menu .bg-blue-btn {
    padding: 0 47.5px;
    font-weight: 700;
    height: 50px;
    line-height: 50px;
    display: inline-block;
  }

  .staco-overly-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }

  .nav-item.home-nav {
    position: inherit !important;
  }

  .mega-menu {
    padding-top: 30px;
    background: transparent;
    top: 45px;
    left: 0px;
    width: 100%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    -webkit-transform-origin: 50% -30px;
    transform-origin: 50% -30px;
    z-index: 100;
    opacity: 0;
    display: none;
    -webkit-transform: rotateX(-15deg) translateZ(0px);
    transform: rotateX(-15deg) translateZ(0px);
  }

  .mega-menu-card {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
  }

  .mega-menu-card .mega-menu-column {
    width: 25%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 21px 40px 40px 40px;
    min-height: 450px;
    background: #ffffff;
    position: relative;
  }

  .mega-menu-card .mega-menu-column:nth-last-child(2) {
    border: none;
  }

  .mega-menu-card .mega-menu-column:nth-last-child(1) {
    border: none;
    background: #E3E9F7;
  }

  .mega-menu-card .mega-menu-column:nth-last-child(1)::after {
    content: '';
    height: 77px;
    width: 77px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(5, 85, 255, 0)), to(rgba(5, 85, 255, 0.15)));
    background: linear-gradient(180deg, rgba(5, 85, 255, 0) 0%, rgba(5, 85, 255, 0.15) 100%);
    right: 20px;
    bottom: 70px;
    position: absolute;
    border-radius: 50%;
    z-index: 0;
  }

  .mega-menu-card .mega-menu-column:nth-last-child(1)::before {
    content: '';
    height: 137px;
    width: 137px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(5, 85, 255, 0.15)), to(rgba(5, 85, 255, 0)));
    background: linear-gradient(180deg, rgba(5, 85, 255, 0.15) 0%, rgba(5, 85, 255, 0) 100%);
    top: 30px;
    left: 20px;
    position: absolute;
    border-radius: 50%;
    z-index: 0;
  }

  .mega-menu-list {
    padding: 0px;
    margin: 0px;
    list-style: none;
  }

  .mega-menu-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    max-height: 56px;
  }

  .mega-menu-list li a {
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 50px;
    color: #444444;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .mega-menu-img {
    background: #405364;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    z-index: 1;
  }

  .mega-menu-img img {
    height: 100%;
    width: 100%;
  }

  .mega-menu-tabcontent {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    padding: 50px 40px;
  }

  .megaMenutabcontent {
    display: none;
    -webkit-animation: fadeEffect 0.4s;
    animation: fadeEffect 0.4s;
  }

  .more-demo-card {
    background: #FAEEF1;
    border-radius: 10px;
    padding: 17px 20px;
    position: relative;
    margin-top: 25px;
  }

  .more-demo-card p {
    color: #111111;
    position: relative;
    z-index: 1;
    margin-bottom: 0px;
  }

  .more-demo-card::after {
    content: '';
    position: absolute;
    top: 12px;
    left: 8px;
    height: 33px;
    width: 33px;
    border-radius: 50%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 183, 202, 0.5)), to(rgba(250, 238, 241, 0.5)));
    background: linear-gradient(180deg, rgba(255, 183, 202, 0.5) 0%, rgba(250, 238, 241, 0.5) 100%);
    z-index: 0;
  }

  .more-demo-card img {
    position: absolute;
    top: 21px;
    right: 20px;
  }

  .hero-section {
    padding: 235px 0px 165px 0px;
    /* background-color: #041e35; */
    background-image: url("../assets/whatsapp.jpeg");
    background-repeat: no-repeat;
    background-size: 100% 128%;
    background-position: center;
    /* position: relative;
            z-index: 0; */
    overflow: hidden;
  }

  .hero-section .shape-img {
    position: absolute;
    z-index: -1;
  }

  .hero-section .shape-img.img-1 {
    top: 25%;
    left: 0;
  }

  .hero-section .shape-img.img-2 {
    bottom: 0;
    left: 40%;
  }

  .hero-section .shape-img.img-3 {
    bottom: 0;
    right: 12%;
  }

  .hero-content-text {
    margin-bottom: 48px;
  }

  .hero-content-text .uig-banner-title {
    line-height: 1.35;
  }

  .hero-content-text p {
    margin-top: 18px;
    font-size: 18px;
    line-height: 36px;
    color: #ffffff;
  }

  .hero-content-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .hero-content-list .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
  }

  .hero-content-list .list-item svg {
    font-size: 18px;
    color: #ffffff;
    position: relative;
    top: -3px;
  }

  .hero-content-list .list-item p {
    font-size: 14px;
    color: #ffffff;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .footer-section {
    background-color: #191c1f;
    position: relative;
    z-index: 0;
  }

  .footer-top {
    padding: 120px 0 70px 0;
  }

  .footer-top .footer-follow p {
    margin-bottom: 0;
  }

  .footer-info {
    margin-bottom: 58px;
  }

  .footer-info .footer-logo {
    margin-bottom: 37px;
    display: block;
  }

  .footer-info p {
    color: #ffffffb2;
  }

  .footer-follow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
  }

  .footer-follow p {
    font-family: "Montserrat Alternates", sans-serif;
    color: #ffffff;
  }

  .footer-follow .social-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 28px;
  }

  .footer-follow .social-link li a {
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .footer-follow .social-link li a img {
    width: 16px;
    height: 16px;
    opacity: 0.7;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .footer-follow .social-link li a:hover {
    opacity: 1;
  }

  .footer-follow .social-link li a:hover img {
    opacity: 1;
  }

  .footer-widget {
    margin-top: 23px;
  }

  .footer-widget .widget-title {
    margin-bottom: 25px;
  }

  .footer-widget .widget-title h6 {
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0;
  }

  .footer-widget .widget-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .footer-widget .widget-list li:not(:first-child) {
    margin-top: 20px;
  }

  .footer-widget .widget-list li a {
    color: #ffffffb2;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .footer-widget .widget-list li a:hover {
    color: #ffffff;
  }

  .footer-copyright p {
    color: #444444;
    margin-bottom: 0;
  }

  .footer-bottom {
    border-top: 1px solid #d9d9d91a;
    padding: 30px 0;
    background: #041e35;
  }

  .social-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 28px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .social-link li a {
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .social-link li a img {
    width: 16px;
    height: 16px;
  }

  .social-link li a:hover {
    opacity: 0.7;
  }

  .footer-copyright p {
    color: #ffffffb2;
  }

  .privacy-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    gap: 30px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .privacy-menu li a {
    color: #ffffffb2;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .privacy-menu li a:hover {
    color: #ffffff;
  }

  .brands-section {
    padding: 93px 0 0 0;
  }

  .brands-section .container {
    position: relative;
  }

  .brands-section-title {
    margin-bottom: 25px;
    text-align: center;
  }

  .brands-section-title h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  }

  .brands-slider {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
  }

  .brands-slider-container {
    width: calc(200px * 12);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0px;
    -webkit-animation: smoothSlider 25s infinite linear;
    animation: smoothSlider 25s infinite linear;
  }

  .brands-slider .slider-item {
    width: 200px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .brands-slider::before {
    position: absolute;
    z-index: 1;
    content: "";
    top: 0;
    left: 0;
    width: 120px;
    height: 100%;
    background: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .brands-slider::after {
    position: absolute;
    z-index: 1;
    content: "";
    top: 0;
    right: 0;
    width: 120px;
    height: 100%;
    background: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }

  .section-title {
    margin-bottom: 42px;
  }

  .usability-section {
    padding: 70px 0 408px 0;
    background-image: url("https://iqtester.org/assets//images/main-demo/dotted-bg.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
  }

  .usability-card {
    width: 100%;
    height: 600px;
    border-radius: 50px;
    background-color: #041e35;
    padding: 84px;
    text-align: center;
    position: relative;
    z-index: 0;
  }

  .usability-card .shape-img {
    position: absolute;
    z-index: -1;
  }

  .usability-card .shape-img.img-1 {
    top: 0;
    left: 57px;
  }

  .usability-card .shape-img.img-2 {
    bottom: 96px;
    right: 0;
  }

  .usability-slider-nav .slick-list {
    padding: 0 !important;
  }

  .usability-slider-nav .slick-track {
    width: 100% !important;
    -webkit-transform: translate3d(0px, 0px, 0px) !important;
    transform: translate3d(0px, 0px, 0px) !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .usability-slider-nav .slider-item {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 180px !important;
    height: 50px !important;
    border-radius: 10px;
    background-color: #ffffff14;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 15px;
    font-weight: 700;
    line-height: 30px;
    color: #ffffff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .usability-slider-nav .slider-item img {
    width: 20px;
    height: 20px;
  }

  .usability-slider-nav .slider-item::before {
    position: absolute;
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff26;
    opacity: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .usability-slider-nav .slider-item.slick-current::before {
    opacity: 1;
    -webkit-animation: tabAnimate 6.5s linear;
    animation: tabAnimate 6.5s linear;
  }

  .usability-slider-for {
    margin-top: 50px;
  }

  .usability-slider-for .slider-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .marketing-section {
    padding: 140px 0 0 0;
  }

  .marketing-img {
    width: 100%;
    height: 420px;
    background-color: #f8f0e6;
    border-radius: 240px 240px 0px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    position: relative;
  }

  .marketing-img>img {
    width: 400px;
    height: 380px;
    border-radius: 250px 250px 0px 0px;
  }

  .marketing-img.v2 {
    background-color: #041e35;
    border-radius: 0px 0px 240px 240px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .marketing-img.v2>img {
    border-radius: 0 0 250px 250px;
  }

  .marketing-img .overlay img {
    width: auto;
    height: auto;
  }

  .marketing-img .overlay-item {
    position: absolute;
  }

  .marketing-img .chart {
    bottom: 0;
    left: 0;
    width: 188px;
    height: 85px;
    border-radius: 0px 45px 0px 0px;
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .marketing-img .emoji-icons {
    bottom: 40px;
    right: 22px;
    width: 209px;
    height: 70px;
    -webkit-box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.15);
    border-radius: 35px;
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .marketing-img .star-1 {
    top: 74px;
    right: 93px;
  }

  .marketing-img .star-2 {
    top: 110px;
    right: 57px;
  }

  .marketing-img .star-3 {
    top: 135px;
    right: 119px;
  }

  .marketing-img .wave-shape {
    bottom: 121px;
    left: 45px;
  }

  .marketing-img .reduction-time {
    top: 30px;
    left: 0;
    width: 170px;
    height: 90px;
    border-radius: 0 45px 0 0;
    background-color: #ffffff;
    padding: 24px 24px 24px 0;
  }

  .marketing-img .reduction-time-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .marketing-img .reduction-time h2 {
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 20px;
  }

  .marketing-img .reduction-time p {
    font-size: 13px;
    line-height: 20px;
  }

  .marketing-img .success-rate {
    bottom: 71px;
    right: 60px;
    width: 170px;
    height: 170px;
  }

  .marketing-img .success-rate-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .marketing-img .pie_progress {
    width: 130px;
    height: 130px;
    margin: auto 0;
    background-color: transparent;
  }

  .marketing-img .pie_progress__label {
    margin-top: 0px;
  }

  .marketing-img .pie_progress__label h4 {
    font-size: 30px;
    color: #000000;
  }

  .marketing-img .pie_progress__label p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  .marketing-content-title {
    margin-bottom: 37px;
  }

  .marketing-content .list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .marketing-content .list li {
    margin-top: 15px;
  }

  .marketing-content .list .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
  }

  .marketing-content .list .list-item svg {
    font-size: 18px;
    color: #999999;
    position: relative;
    top: -6px;
  }

  .testimonial-seciton {
    padding: 140px 0;
  }

  .testimonial-card {
    border-radius: 50px;
    background-color: #242549;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  .testimonial-card-left,
  .testimonial-card-right {
    padding: 40px;
    width: 50%;
    height: 530px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background: #041e35
  }

  .testimonial-card-left {
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .testimonial-card-left::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #041e35;
  }

  .testimonial-card-left .shape-img {
    position: absolute;
    z-index: -1;
  }

  .testimonial-card-left .shape-img.img-1 {
    top: 49px;
    left: 0;
  }

  .testimonial-card-left .shape-img.img-2 {
    bottom: 0;
    left: 96px;
  }

  .testimonial-card-left .shape-img.img-3 {
    top: 40%;
    right: 0;
  }

  .testimonial-card-left .shape-img.img-4 {
    top: 70px;
    right: 65px;
  }

  .testimonial-card-left .shape-img.img-4 .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .testimonial-card-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .testimonial-slider-nav .slick-list {
    padding: 0 !important;
  }

  .testimonial-slider-nav .slick-track {
    width: 100% !important;
    -webkit-transform: translate3d(0px, 0px, 0px) !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }

  .testimonial-slider-nav .slider-item {
    position: relative;
    overflow: hidden;
    width: 270px !important;
    height: 350px !important;
    border-radius: 135px;
    background-color: #ffffff14;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    display: none;
  }

  .testimonial-slider-nav .slider-item img {
    width: 100%;
    height: 100%;
  }

  .testimonial-slider-nav .slider-item.slick-current,
  .testimonial-slider-nav .slider-item.slick-active {
    display: block;
  }

  .testimonial-slider-for .slider-item p {
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 36px;
    color: #ffffff;
  }

  .testimonial-slider-for .slider-item .author-info {
    color: #ffffff;
  }

  .testimonial-slider-for .slider-item .author-info .name {
    font-weight: 700;
    margin-right: 10px;
  }

  .testimonial-slider-for .slider-item .slider-item-user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .testimonial-slider-for .slider-info {
    position: absolute;
    bottom: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
  }

  .testimonial-slider-for .slider-counter p {
    color: #ffffff;
    letter-spacing: 5px;
    margin-bottom: 0;
  }

  .testimonial-slider-for .slider-arrow {
    cursor: pointer;
  }

  .testimonial-slider-for .slider-arrow svg {
    color: #ffffff;
  }

  .faq-seciton {
    padding: 92px 0 140px 0;
  }

  .leave-message1 {
    display: block;
  }

  .leave-message2 {
    display: none;
  }

  .leave-message h2 {
    font-size: 18px;
    line-height: 30px;
    color: #000000;
  }

  .leave-message h3 {
    color: #444444;
  }

  .leave-message .msg-btn {
    position: relative;
  }

  .leave-message .msg-btn .rotate-icon {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .leave-message .msg-btn .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .theme-accordion .accordion-item {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }

  .theme-accordion .accordion-item:not(:first-child) {
    margin-top: 20px;
  }

  .theme-accordion .accordion-item:first-child {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  .theme-accordion .accordion-item:last-child {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }

  .theme-accordion .accordion-header {
    border: 0 !important;
    border-radius: 15px !important;
    background-color: transparent;
  }

  .theme-accordion .accordion-button {
    border-radius: 15px !important;
    font-family: "Montserrat Alternates", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #111111;
    padding: 18px 20px 20px 50px;
    position: relative;
  }

  .theme-accordion .accordion-button::after {
    position: absolute;
    content: url("https://iqtester.org/assets/images/icons/accordion-plus.png");
    top: 22px;
    left: 20px;
    width: 20px;
    height: 20px;
    border: 0 !important;
    background-image: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .theme-accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .theme-accordion .accordion-button:not(.collapsed)::after {
    content: url("https://iqtester.org/assets/images/icons/accordion-minus.png");
  }

  .theme-accordion .accordion-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .theme-accordion .accordion-collapse.collapse.show {
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.08);
  }

  .theme-accordion .accordion-body {
    padding: 0 20px 20px 50px;
  }

  .lb-outerContainer {
    height: 100vh !important;
    position: fixed !important;
    left: 0px !important;
    top: 0px !important;
    width: 100% !important;
    border-radius: 0px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.7);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .lightbox .lb-image {
    border: none;
    border-radius: 0px;
  }

  .lb-nav a.lb-next {
    opacity: 1;
    right: 30px;
    width: 20%;
    margin-right: 30px;
  }

  .lb-nav a.lb-prev {
    opacity: 1;
    left: 30px;
    width: 20%;
    margin-left: 30px;
  }

  .lb-data .lb-close {
    position: fixed;
    top: 30px;
    right: 30px;
  }

  .bg-white-btn {
    padding: 12px 39px;
    background-color: #faaa00;
    border-radius: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: capitalize;
    color: #111111 !important;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    font-weight: 700;
  }

  .bg-white-btn .btn-inner {
    display: inline-block;
    /* background: #041e35; */
  }

  .bg-white-btn span {
    position: relative;
    z-index: 11;
    -webkit-transition: opacity 0.6s, -webkit-transform 0.8s;
    transition: opacity 0.6s, -webkit-transform 0.8s;
    transition: opacity 0.6s, transform 0.8s;
    transition: opacity 0.6s, transform 0.8s, -webkit-transform 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
    transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
    color: #fff;
  }

  .bg-white-btn span:nth-child(2) {
    display: inline-block;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 80%);
    transform: translate(-50%, 80%);
    opacity: 0;
  }

  .bg-white-btn span {
    color: #111111;
  }

  .bg-white-btn:hover .btn-inner,
  .bg-white-btn:focus .btn-inner {
    -webkit-transform: translateY(-150%);
    transform: translateY(-150%);
  }

  .bg-white-btn:hover span:nth-child(2),
  .bg-white-btn:focus span:nth-child(2) {
    -webkit-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
    opacity: 1;
  }

  .bg-white-btn:hover span .btn-normal-text,
  .bg-white-btn:focus span .btn-normal-text {
    opacity: 0;
  }

  .bg-blue-btn {
    padding: 18px 46px;
    background-color: #faaa00 !important;
    border-radius: 100px;
    color: #ffffff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    z-index: 0;
    font-weight: 700;
  }

  .bg-blue-btn .btn-inner {
    display: inline-block;
  }

  .bg-blue-btn span {
    position: relative;
    z-index: 11;
    -webkit-transition: opacity 0.6s, -webkit-transform 0.8s;
    transition: opacity 0.6s, -webkit-transform 0.8s;
    transition: opacity 0.6s, transform 0.8s;
    transition: opacity 0.6s, transform 0.8s, -webkit-transform 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
    transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
    color: #fff;
  }

  .bg-blue-btn span:nth-child(2) {
    display: inline-block;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 80%);
    transform: translate(-50%, 80%);
    opacity: 0;
  }

  .bg-blue-btn:hover,
  .bg-blue-btn:focus {
    color: #ffffff;
  }

  .bg-blue-btn:hover .btn-inner,
  .bg-blue-btn:focus .btn-inner {
    -webkit-transform: translateY(-150%);
    transform: translateY(-150%);
  }

  .bg-blue-btn:hover span:nth-child(2),
  .bg-blue-btn:focus span:nth-child(2) {
    -webkit-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
    opacity: 1;
  }

  .bg-blue-btn:hover span .btn-normal-text,
  .bg-blue-btn:focus span .btn-normal-text {
    opacity: 0;
  }

  .text-link {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
    color: #0095ff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .text-link svg {
    font-size: 20px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .text-link:hover,
  .text-link:focus {
    color: #0095ff;
  }

  .text-link:hover svg,
  .text-link:focus svg {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .hero-badge {
    border-radius: 0px 20px 0px 0px;
    background-color: #faaa00;
    padding: 0px 10px;
    position: relative;
  }

  .hero-badge::before {
    position: absolute;
    content: url("https://iqtester.org/assets/images/icons/badge-icon.svg");
    top: -52px;
    right: -32px;
    -webkit-animation: badgeAnimate 1.5s infinite ease-in-out;
    animation: badgeAnimate 1.5s infinite ease-in-out;
  }

  .marketing-badge {
    border-radius: 0px 20px 0px 0px;
    background-color: #faaa00;
    padding: 0 10px;
  }

  .section-title {
    margin-bottom: 38px;
  }

  .section-title .sub-title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    color: #0095ff;
    letter-spacing: 0.2em;
    margin-bottom: 12px;
    display: block;
  }

  .section-title .title {
    line-height: 1.55;
  }

  .section-title .love-icon svg {
    color: #f7578c;
  }

  .staco-scroll-top {
    position: fixed;
    right: 30px;
    bottom: 25px;
    width: 45px;
    height: 45px;
    cursor: pointer;
    display: block;
    border-radius: 100%;
    -webkit-box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
    box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(0.75rem);
    transform: translateY(0.75rem);
    -webkit-transition: all .2s linear, margin-right 0s;
    transition: all .2s linear, margin-right 0s;
  }

  .staco-scroll-top-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    color: rgba(0, 149, 255, 0.8);
  }

  .staco-scroll-top>svg path {
    fill: none;
  }

  .staco-scroll-top>svg.progress-circle path {
    stroke: rgba(0, 149, 255, 0.8);
    stroke-width: 4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  button:after,
  button:before {
    position: absolute;
    font-family: "Barrio", sans-serif;
    font-weight: bold;
    font-size: 1rem;
    color: #ffffff;
    transition: 0.3s all ease;
  }

  button:before {
    content: "";
    position: absolute;
    left: 1.4rem;
    top: 0.9rem;
  }

  button:after {
    content: "";
    position: absolute;
    left: 0.8rem;
    top: 1rem;
  }

  button:hover {
    box-shadow: 1px 1px 20px rgba(150, 150, 150, 0.2);
    top: -0.1rem;
    right: -0.1rem;
    cursor: pointer;
    color: white;
  }

  button:hover:before {
    content: "";
    position: absolute;
    left: 0.8rem;
    top: 0.7rem;
    transform: translateY(-1px);
  }

  button:hover:after {
    content: "";
    position: absolute;
    left: 1.1rem;
    top: 1rem;
  }

  @media (max-width: 767px) {
    .header-logo {
      display: none;
    }
  }

  /*! CSS Used from: https://iqtester.org/assets/css/responsive.css */
  @media screen and (max-width: 1399px) {
    .header-extra {
      gap: 20px;
    }

    .main-menu {
      gap: 20px;
    }
  }

  @media screen and (max-width: 1199px) {
    .footer-follow {
      gap: 15px;
    }

    .footer-follow .social-link {
      gap: 15px;
    }

    .section-title h2 {
      font-size: 32px;
      line-height: 45px;
    }

    h1 {
      font-size: 54px;
    }

    .section-title {
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: 991px) and (max-width: 1200px) {
    .header-section .main-menu li a {
      font-size: 13px;
    }

    .header-section .header-extra li a {
      font-size: 13px;
    }

    .main-menu {
      gap: 25px;
    }

    .header-extra {
      gap: 25px;
    }
  }

  @media screen and (max-width: 991px) {
    .nav-expander {
      display: block;
    }

    .mobile-navbar-menu span.submenu-button::before {
      right: 26px;
    }

    .mobile-navbar-menu span.submenu-button::after {
      right: 21px;
    }

    .header-navbar-content {
      position: fixed;
      top: 0;
      left: 0;
      width: 320px;
      height: 100%;
      background-color: black;
      padding: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important;
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      -webkit-transform: translateX(-155%);
      transform: translateX(-155%);
      height: 100vh;
    }

    .main-menu {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .main-menu li {
      position: relative;
    }

    .main-menu li a {
      padding: 0px;
    }

    .main-menu li:hover .dropdown-toggle::after {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    .main-menu li:hover .submenu {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }

    .main-menu .submenu {
      display: none;
      position: unset;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: transparent;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-top: 0;
      padding: 5px 0;
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      -webkit-transition: 0.3s;
      transition: 0.3s;
      opacity: 1;
      visibility: visible;
    }

    .main-menu .submenu li a {
      padding: 10px 15px;
    }

    .header-extra {
      margin-top: 30px;
    }

    .header-section:not(.v6) {
      background: #ffffff;
      padding: 15px 0px;
    }

    .header-section .logo-light {
      display: none;
    }

    .header-section .logo-dark {
      display: block !important;
    }

    .hero-content-text p {
      max-width: 500px;
      margin: auto;
      margin-top: 15px;
    }

    .usability-section {
      padding: 0px 0 70px 0;
    }

    .marketing-section {
      padding-top: 70px;
    }

    .usability-card {
      height: 600px;
      padding: 84px 50px 20px 50px;
    }

    .usability-slider-nav .slider-item {
      width: 175px !important;
      gap: 10px;
    }

    .usability-slider-for {
      margin-top: 40px;
    }

    .marketing-img {
      width: 480px;
      height: 420px;
      margin-bottom: 40px;
    }

    .testimonial-card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .testimonial-card-left,
    .testimonial-card-right {
      width: 100%;
      min-height: 530px;
      height: unset;
    }

    .footer-card {
      margin-bottom: 40px;
    }

    .mobile-navbar-menu .sub-menu li:last-child a {
      padding-bottom: 18px;
    }

    .header-section.v1 .main-menu .submenu li a {
      color: #ffffffb2;
    }

    .hero-content {
      text-align: center;
    }

    .section-title h2 br {
      display: none;
    }

    .hero-content-list {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .hero-content-button a {
      margin: auto;
    }
  }

  @media screen and (max-width: 767px) {

    .mobile-navbar-menu .nav-menu,
    .mobile-navbar-menu .nav-buttons {
      width: 540px;
    }

    .hero-section {
      padding: 150px 0px 80px;
    }

    .hero-content {
      margin-bottom: 16px;
    }

    .hero-content-text {
      margin-bottom: 40px;
    }

    .hero-content-text p {
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      margin: auto;
      margin-top: 20px;
    }

    .brands-section {
      padding-top: 70px;
    }

    .brands-slider-container {
      gap: 50px;
    }

    .brands-slider .slider-item {
      width: 95px;
      height: 18px;
    }

    .usability-section {
      padding: 40px 0px 80px;
    }

    .usability-card {
      border-radius: 30px;
      height: auto;
      padding: 60px 25px 25px 25px;
    }

    .usability-slider-nav .slick-track {
      gap: 10px;
    }

    .usability-slider-nav .slider-item {
      width: 130px !important;
      gap: 5px;
      line-height: 22px;
    }

    .usability-slider-nav .slider-item img {
      width: 15px;
      height: 15px;
    }

    .marketing-content-title {
      margin-bottom: 30px;
    }

    .marketing-content-title h2 {
      font-size: 32px;
      line-height: 45px;
    }

    .marketing-img .emoji-icons {
      padding: 10px;
      height: 48px;
      width: 145px;
    }

    .marketing-img .chart {
      width: 131px;
      height: 60px;
      padding: 20px 20px 0px 0px;
    }

    .marketing-img {
      height: auto;
    }

    .marketing-img>img {
      width: 85%;
      height: auto;
    }

    .marketing-img .pie_progress__label h4 {
      font-size: 20px;
      line-height: 15px;
    }

    .marketing-img .pie_progress__label p {
      font-size: 10px;
      line-height: 15px;
    }

    .marketing-img .reduction-time {
      height: 62px;
      width: 118px;
      padding: 17px 27px 6px 0px;
    }

    .marketing-img .reduction-time h2 {
      font-size: 20px;
      line-height: 15px;
    }

    .marketing-img .reduction-time p {
      font-size: 10px;
      line-height: 15px;
    }

    .marketing-img .success-rate {
      height: 118px;
      width: 118px;
      bottom: 30px;
      right: 49px;
    }

    .marketing-img .pie_progress__svg svg {
      top: 25px;
      left: 26px;
      width: 60%;
      height: 60%;
      margin: auto;
    }

    .marketing-section {
      padding-top: 80px;
    }

    .marketing-img.v2 {
      width: 100%;
      margin-bottom: 42px;
    }

    .pie_progress__svg svg {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      margin: auto;
    }

    .testimonial-seciton {
      padding: 60px 0px 60px;
    }

    .testimonial-slider-for .slider-item p {
      font-size: 15px;
      line-height: 30px;
      margin-bottom: 23px;
    }

    .testimonial-card-left {
      min-height: auto;
      padding: 50px 20px 100px 20px;
    }

    .testimonial-card-right {
      min-height: auto;
      padding: 42px 20px 44px 20px;
    }

    .testimonial-slider-for .slider-info {
      position: relative;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-top: 15px;
    }

    .testimonial-card-left .shape-img.img-4 {
      top: auto;
      bottom: 19px;
      left: 36px;
      right: auto;
    }

    .faq-seciton {
      padding-top: 40px;
      padding-bottom: 80px;
    }

    .theme-accordion .accordion-button {
      font-size: 16px;
      line-height: 30px;
    }

    .leave-message {
      margin-bottom: 0px;
      text-align: center;
    }

    .leave-message1 {
      display: none;
    }

    .leave-message2 {
      display: block;
      margin-top: 50px;
    }

    .leave-message .text-link {
      margin: auto;
    }

    .footer-top {
      padding: 80px 0px 65px;
    }

    .footer-info {
      margin-bottom: 18px;
    }

    .footer-info .footer-logo {
      margin-bottom: 24px;
    }

    .footer-card {
      margin-bottom: 0px;
    }

    .footer-widget .widget-list li a {
      line-height: 36px;
    }

    .footer-bottom {
      padding: 25px 0px 20px;
    }

    .footer-widget {
      margin-top: 40px;
    }

    .footer-copyright {
      text-align: center;
    }

    .privacy-menu {
      margin-bottom: 10px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      gap: 16px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .privacy-menu li,
    .privacy-menu li a {
      line-height: 1;
    }

    .marketing-badge {
      padding: 0px 10px;
    }

    .footer-widget .widget-list li:not(:first-child) {
      margin-top: 0px;
    }

    .footer-widget .widget-title {
      margin-bottom: 15px;
    }

    h1 {
      font-size: 40px;
    }

    h3 {
      font-size: 26px;
    }

    h6,
    body {
      font-size: 14px;
      line-height: 26px;
    }

    .md-mb-40 {
      margin-bottom: 40px !important;
    }

    .md-mb-50 {
      margin-bottom: 50px !important;
    }

    .md-pt-60 {
      padding-top: 60px !important;
    }

    .md-pt-70 {
      padding-top: 70px !important;
    }

    .md-pb-0 {
      padding-bottom: 0px !important;
    }

    .md-pb-80 {
      padding-bottom: 80px !important;
    }

    .section-title .sub-title {
      font-size: 16px;
    }

    .usability-section {
      background-image: none;
    }

    .theme-accordion .accordion-button::after {
      top: 24px;
    }

    .footer-widget .widget-list li a {
      padding-bottom: 10px;
      display: block;
    }

    .section-title .title {
      font-size: 32px;
      line-height: 45px;
    }

    .hero-section .shape-img.img-2 {
      bottom: 0;
      left: -15px;
      max-width: 190px;
    }

    .hero-section .shape-img.img-3 {
      bottom: 0px;
      right: -30px;
      max-width: 200px;
    }

    .hero-badge::before {
      top: -35px;
    }

    .staco-scroll-top {
      bottom: -90px;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }

  @media screen and (max-width: 575px) {

    .mobile-navbar-menu .nav-menu,
    .mobile-navbar-menu .nav-buttons {
      width: 100%;
    }

    .usability-card {
      padding: 61px 30px 20px 30px;
    }

    .usability-slider-nav .slider-item {
      width: 110px !important;
      height: 40px !important;
    }

    .usability-slider-nav .slider-item-text {
      display: none;
    }

    .usability-slider-nav .slider-item img {
      display: none;
    }

    .marketing-img {
      width: 100%;
    }

    .footer-info {
      margin-bottom: 40px;
    }

    .footer-info .footer-logo {
      margin-bottom: 30px;
    }

    .mobile-navbar-menu .nav-buttons {
      padding-left: 20px;
      padding-right: 20px;
    }

    .container {
      padding: 0px 20px;
    }

    .mobile-navbar-menu .nav-menu li a {
      padding-left: 20px;
      padding-right: 20px;
    }

    .mobile-navbar-menu .nav-menu {
      padding: 0;
    }

    .hero-content-list {
      gap: 15px;
    }
  }

  @media screen and (max-width: 480px) {
    .header-navbar-content {
      width: 250px;
    }

    .usability-card {
      height: auto;
      padding: 61px 13px 20px 13px;
    }

    .usability-slider-nav .slider-item {
      width: -webkit-fit-content !important;
      width: -moz-fit-content !important;
      width: fit-content !important;
      height: 40px !important;
      padding: 5px 13px;
      line-height: 30px;
    }

    .usability-slider-nav .slider-item-text {
      display: none;
    }

    .marketing-img {
      height: auto;
    }

    .marketing-img>img {
      width: 85%;
      height: auto;
    }

    .testimonial-card-left,
    .testimonial-card-right {
      padding: 30px 20px 40px;
    }

    .testimonial-card-left .shape-img.img-4 {
      top: 20px;
      right: 33px;
    }

    .testimonial-slider-for {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 20px;
    }

    .testimonial-slider-for .slider-info {
      position: relative;
    }

    .testimonial-slider-for .slider-info {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 375px) {
    .usability-slider-nav .slick-track {
      gap: 5px;
    }

    .usability-slider-nav .slider-item {
      width: -webkit-fit-content !important;
      width: -moz-fit-content !important;
      width: fit-content !important;
      height: 40px !important;
      padding: 0 10px;
    }

    .usability-slider-nav .slider-item img {
      display: none;
    }

    .marketing-img .success-rate {
      bottom: 71px;
      right: 30px;
    }

    .marketing-img .wave-shape {
      bottom: 121px;
      left: 0px;
    }

    .section-title .title,
    .marketing-content-title h2,
    .section-title h2 {
      font-size: 30px;
      line-height: 42px;
    }

    h1 {
      font-size: 35px;
      line-height: 50px;
    }

    .brands-section-title h2 {
      font-size: 16px;
    }
  }

  /*! CSS Used keyframes */
  @-webkit-keyframes fadeEffect {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeEffect {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes smoothSlider {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-200px * 6));
      transform: translateX(calc(-200px * 6));
    }
  }

  @keyframes smoothSlider {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-200px * 6));
      transform: translateX(calc(-200px * 6));
    }
  }

  @-webkit-keyframes tabAnimate {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  @keyframes tabAnimate {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  @-webkit-keyframes badgeAnimate {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes badgeAnimate {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  /*! CSS Used fontfaces */
</style>
<template>
    <Webheader />
    <section class="terms-and-service-body">
        <div class="container">
            <p class="uppercase">Last updated: 28 March 2023</p>
            <h1>Privacy Policy</h1>
            <div class="terms-and-service-inner">
                <div class="row main-content" data-spy="scroll" data-target="#list-example" data-offset="20">
                    <div class="col-md-4 sidebar" style="">
                        <div class="terms-and-service-sidebar sidebar__inner" id="list-example"
                            style="position: relative;">
                            <h6 class="content-table-title dm-sans"><img
                                    src="https://iqtester.org/assets/images/auth-and-utility/textalign-left.svg"
                                    alt="icon"> TABLE OF
                                CONTENTS</h6>
                            <ol>
                                <li><a href="#one" class="list-group-item list-group-item-action">Our privacy</a></li>
                                <li><a href="#two" class="list-group-item list-group-item-action">Information we
                                        collect</a></li>
                                <li><a href="#three" class="list-group-item list-group-item-action">How we use your
                                        information</a></li>
                                <li><a href="#four" class="list-group-item list-group-item-action">How we share your
                                        information</a></li>
                                <li><a href="#five" class="list-group-item list-group-item-action">How we protect your
                                        information</a></li>
                                <li><a href="#six" class="list-group-item list-group-item-action">Your rights</a></li>
                                <li><a href="#sevven" class="list-group-item list-group-item-action">Updates to this
                                        policy</a></li>
                                <li><a href="#eight" class="list-group-item list-group-item-action">Contact Us</a></li>
                            </ol>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="terms-and-service-content scrollspy-example" data-bs-spy="scroll"
                            data-bs-target="#list-example" data-bs-offset="0" tabindex="0">
                            <div id="one">
                                <h4>1. Our privacy</h4>
                                <p>
                                    At staco, we are committed to protecting your privacy and personal information. This
                                    privacy policy explains how
                                    we collect, use, and share your
                                    information when you use our services. By using our services, you agree to the terms
                                    of this privacy policy.
                                </p>
                                <p>
                                    We take your privacy seriously and are committed to protecting your personal
                                    information. This privacy policy
                                    explains how we collect, use, and
                                    share your information when you use our services.
                                </p>
                            </div>
                            <div id="two">
                                <h4>2. Information we collect</h4>
                                <p>
                                    We may collect information about you when you use our services, such as your name,
                                    email address, postal
                                    address, phone number, and payment information.
                                    We may also collect information about your device and how you use our services,
                                    including your IP address,
                                    browser type, and operating system.
                                </p>
                                <p>
                                    <strong>3.1</strong> We collect this information in several ways, including when you
                                    provide it to us directly,
                                    when you use our services, and when we
                                    obtain it from third-party sources. We may also use cookies and similar technologies
                                    to collect information
                                    about your browsing behavior and preferences.
                                </p>
                                <p>
                                    <strong>3.2</strong> When you use our services, and when we obtain it from
                                    third-party sources. We may also use
                                    cookies and similar technologies to
                                    collect information about your browsing behavior.
                                </p>
                                <p>
                                    <strong>3.3</strong> Including when you provide it to us directly, when you use our
                                    services, and when we obtain
                                    it from third-party sources. We may
                                    also use cookies and similar technologies to collect information about your browsing
                                    behavior and preferences.
                                </p>
                            </div>
                            <div id="three">
                                <h4>3. How we use your information</h4>
                                <p>
                                    We use your information to provide and improve our services, to communicate with
                                    you, and to personalize your
                                    experience. Specifically, we may use your information for the following purposes:
                                </p>
                                <ul>
                                    <li>To process your transactions and provide customer support</li>
                                    <li>To send you newsletters, promotions, and other marketing communications</li>
                                    <li>To personalize your experience and recommend products and services based on your
                                        interests and preferences
                                    </li>
                                    <li>To conduct research and analysis to improve our services and offerings</li>
                                    <li>To comply with legal and regulatory requirements</li>
                                </ul>
                            </div>

                            <div id="four">
                                <h4>To comply with legal and regulatory requirements</h4>
                                <p>
                                    We may share your information with third-party service providers who help us provide
                                    our services, such as
                                    payment processors and customer support providers.
                                    We may also share your information with our partners and affiliates for marketing
                                    purposes. In some cases, we
                                    may share your information with government
                                    authorities or law enforcement agencies to comply with legal requirements or protect
                                    our rights and property.
                                    <br>We will never sell your information to third parties.
                                </p>
                            </div>

                            <div id="five">
                                <h4>5. How we protect your information</h4>
                                <p>
                                    We take reasonable measures to protect your information from unauthorized access,
                                    disclosure, alteration, and
                                    destruction. Specifically, we implement physical,
                                    technical, and administrative safeguards to protect your information. However, no
                                    method of transmission over
                                    the internet or electronic storage is 100% secure,
                                    and we cannot guarantee the security of your information.
                                </p>
                                <p>
                                    <strong>To identify:</strong> We collect this information in several ways, including
                                    when you provide it to us
                                    directly, when you use our services,
                                    and when we obtain it from third-party sources.
                                </p>
                                <p>
                                    <strong>To stay connected:</strong> When you use our services, and when we obtain it
                                    from third-party sources.
                                    We may also use cookies and similar
                                    technologies to collect information about your browsing behavior.
                                </p>
                                <p>
                                    <strong>To get feedback:</strong> Including when you provide it to us directly, when
                                    you use our services, and
                                    when we obtain it from third-party sources.
                                    We may also use cookies and similar technologies to collect information about your
                                    browsing behavior and
                                    preferences.

                                </p>
                            </div>

                            <div id="six">
                                <h4>6. Your rights </h4>
                                <p>
                                    You have certain rights regarding your personal information, including the right to
                                    access and correct your
                                    information, the right to request that we delete your information,
                                    and the right to opt-out of receiving marketing communications from us. If you wish
                                    to exercise any of these
                                    rights, please contact us at [<b>contact email</b>].
                                </p>
                            </div>

                            <div id="sevven">
                                <h4>7. Updates to this policy</h4>
                                <p>
                                    We may update this privacy policy from time to time. We will notify you of any
                                    material changes by posting the
                                    updated policy on our website.
                                    We encourage you to review this policy periodically to stay informed about how we
                                    are protecting your
                                    information.
                                </p>
                            </div>

                            <div id="eight">
                                <h4>8. Contact us</h4>
                                <p>If you have any questions about these Terms, please contact us at [<a
                                        href="contact-us.html">Contact page</a>].
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footers />
</template>
<script>
    import Webheader from '../components/Webheader.vue';
    import Footers from '../components/Footer.vue';
    export default {
        name: 'App',

        // Register the component
        components: {
            Webheader,
            Footers
        },
    };
</script>
<style scoped>
 @font-face {
    font-family: BalooBhai2;
    src: url("../assets/baloo-bhai-2/BalooBhai2-Regular.ttf");
  }

  *{
    font-family: BalooBhai2 !important;
  }
    /*! CSS Used from: https://iqtester.org/assets/css/bootstrap.min.css */
    *,
    ::after,
    ::before {
        box-sizing: border-box;
    }

    h1,
    h4,
    h6 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    h1 {
        font-size: calc(1.375rem + 1.5vw);
    }

    @media (min-width:1200px) {
        h1 {
            font-size: 2.5rem;
        }
    }

    h4 {
        font-size: calc(1.275rem + .3vw);
    }

    @media (min-width:1200px) {
        h4 {
            font-size: 1.5rem;
        }
    }

    h6 {
        font-size: 1rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol,
    ul {
        padding-left: 2rem;
    }

    ol,
    ul {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    b,
    strong {
        font-weight: bolder;
    }

    a {
        color: var(--bs-link-color);
        text-decoration: underline;
    }

    a:hover {
        color: var(--bs-link-hover-color);
    }

    img {
        vertical-align: middle;
    }

    .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width:576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width:768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width:992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width:1200px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (min-width:1400px) {
        .container {
            max-width: 1320px;
        }
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }

    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }

    @media (min-width:768px) {
        .col-md-4 {
            flex: 0 0 auto;
            width: 33.33333333%;
        }

        .col-md-8 {
            flex: 0 0 auto;
            width: 66.66666667%;
        }
    }

    .list-group-item-action {
        width: 100%;
        color: var(--bs-list-group-action-color);
        text-align: inherit;
    }

    .list-group-item-action:focus,
    .list-group-item-action:hover {
        z-index: 1;
        color: var(--bs-list-group-action-hover-color);
        text-decoration: none;
        background-color: var(--bs-list-group-action-hover-bg);
    }

    .list-group-item-action:active {
        color: var(--bs-list-group-action-active-color);
        background-color: var(--bs-list-group-action-active-bg);
    }

    .list-group-item {
        position: relative;
        display: block;
        padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
        color: var(--bs-list-group-color);
        text-decoration: none;
        background-color: var(--bs-list-group-bg);
        border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
    }

    .list-group-item:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }

    .list-group-item:last-child {
        border-bottom-right-radius: inherit;
        border-bottom-left-radius: inherit;
    }

    .list-group-item:disabled {
        color: var(--bs-list-group-disabled-color);
        pointer-events: none;
        background-color: var(--bs-list-group-disabled-bg);
    }

    /*! CSS Used from: https://iqtester.org/assets/css/headline.css */
    *,
    *::after,
    *::before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    html * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a {
        text-decoration: none;
    }

    /*! CSS Used from: https://iqtester.org/assets/css/style.css */
    * {
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .dm-sans {
        font-family: "DM Sans", sans-serif !important;
    }

    h1,
    h4,
    h6 {
        font-family: "Montserrat Alternates", sans-serif;
        font-weight: 700;
        letter-spacing: 0;
        color: #111111;
        line-height: 1.46;
    }

    h1 {
        font-size: 60px;
    }

    h4 {
        font-size: 24px;
    }

    h6 {
        font-size: 15px;
        font-weight: 500;
    }

    .uppercase {
        text-transform: uppercase !important;
    }

    *:focus {
        outline: none;
    }

    img {
        max-width: 100%;
    }

    ::selection {
  background-color: #007bff; /* Use a blue color for the selection background */
  color: #fff; /* Use white for the selected text color */
}

    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    a:hover {
        text-decoration: none;
        color: inherit;
    }

    a:focus {
        text-decoration: none;
        color: inherit;
        outline: none;
        border: none;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1170px;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .row {
        margin: 0px -15px !important;
    }

    .col-md-4,
    .col-md-8 {
        padding: 0px 15px !important;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .terms-and-service-body {
        padding-top: 111px;
        padding-bottom: 127px;
    }

    .terms-and-service-body h1 {
        margin-top: 11px;
    }

    .terms-and-service-inner {
        margin-top: 112px;
    }

    .terms-and-service-content p {
        margin-bottom: 20px;
    }

    .terms-and-service-content p b {
        color: #111111;
    }

    .terms-and-service-content p strong {
        color: #111111;
    }

    .terms-and-service-content p a {
        color: #0095ff;
    }

    .terms-and-service-content h4 {
        margin-bottom: 7px;
    }

    .content-table-title {
        text-transform: uppercase;
        color: #0095FF;
        margin-bottom: 20px;
    }

    .content-table-title img {
        margin-right: 15px;
    }

    .terms-and-service-sidebar {
        left: inherit !important;
    }

    .terms-and-service-sidebar ol li {
        line-height: 36px;
    }

    .terms-and-service-sidebar ol li a:hover {
        color: #111111;
    }

    .uppercase {
        text-transform: uppercase;
    }

    /*! CSS Used from: https://iqtester.org/assets/css/responsive.css */
    @media screen and (max-width: 1199px) {
        h1 {
            font-size: 54px;
        }
    }

    @media screen and (max-width: 991px) {
        .terms-and-service-body {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .terms-and-service-inner {
            margin-top: 80px;
        }
    }

    @media screen and (max-width: 767px) {
        h1 {
            font-size: 40px;
        }

        h6 {
            font-size: 14px;
            line-height: 26px;
        }

        .terms-and-service-inner .sidebar {
            margin-bottom: 40px;
        }

        .terms-and-service-sidebar {
            position: relative !important;
            top: 0px !important;
            width: 100% !important;
        }

        .terms-and-service-body h1 {
            font-size: 32px;
        }

        .terms-and-service-body {
            padding-top: 60px;
            padding-bottom: 50px;
        }

        .terms-and-service-inner {
            margin-top: 60px;
        }
    }

    @media screen and (max-width: 575px) {
        .container {
            padding: 0px 20px;
        }
    }

    @media screen and (max-width: 375px) {
        h1 {
            font-size: 35px;
            line-height: 50px;
        }
    }
</style>
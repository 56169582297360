<template>
  <Webheader />
  <section class="top-blog" style="margin-top: 10%;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;    max-width: 1300px;
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;">
    <a href="/blogpost/1" style="text-decoration: none;">
    <div class="blog-card">
        <img src="https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg" alt="Blog Image">
        <div class="blog-content">
            <div class="blog-title">The Genius of Einstein: Exploring IQ and Beyond</div>
            <div class="blog-meta">IQ Spark · May 5, 2024 · Blog · No Comments</div>
            <div class="blog-description">
                Few names evoke the concept of genius as powerfully as Albert Einstein. Renowned for his groundbreaking theories of relativity and [...]
            </div>
        </div>
    </div>
    </a>
    <a href="/blogpost/1" style="text-decoration: none;">
    <div class="blog-card">
        <img src="https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg" alt="Blog Image">
        <div class="blog-content">
            <div class="blog-title">The Genius of Einstein: Exploring IQ and Beyond</div>
            <div class="blog-meta">IQ Spark · May 5, 2024 · Blog · No Comments</div>
            <div class="blog-description">
                Few names evoke the concept of genius as powerfully as Albert Einstein. Renowned for his groundbreaking theories of relativity and [...]
            </div>
        </div>
    </div>
    </a>
    <a href="/blogpost/1" style="text-decoration: none;">
    <div class="blog-card">
        <img src="https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg" alt="Blog Image">
        <div class="blog-content">
            <div class="blog-title">The Genius of Einstein: Exploring IQ and Beyond</div>
            <div class="blog-meta">IQ Spark · May 5, 2024 · Blog · No Comments</div>
            <div class="blog-description">
                Few names evoke the concept of genius as powerfully as Albert Einstein. Renowned for his groundbreaking theories of relativity and [...]
            </div>
        </div>
    </div>
    </a>
    <a href="/blogpost/1" style="text-decoration: none;">
    <div class="blog-card">
        <img src="https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg" alt="Blog Image">
        <div class="blog-content">
            <div class="blog-title">The Genius of Einstein: Exploring IQ and Beyond</div>
            <div class="blog-meta">IQ Spark · May 5, 2024 · Blog · No Comments</div>
            <div class="blog-description">
                Few names evoke the concept of genius as powerfully as Albert Einstein. Renowned for his groundbreaking theories of relativity and [...]
            </div>
        </div>
    </div>
    </a>
    <a href="/blogpost/1" style="text-decoration: none;">
    <div class="blog-card">
        <img src="https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg" alt="Blog Image">
        <div class="blog-content">
            <div class="blog-title">The Genius of Einstein: Exploring IQ and Beyond</div>
            <div class="blog-meta">IQ Spark · May 5, 2024 · Blog · No Comments</div>
            <div class="blog-description">
                Few names evoke the concept of genius as powerfully as Albert Einstein. Renowned for his groundbreaking theories of relativity and [...]
            </div>
        </div>
    </div>
    </a>
    <a href="/blogpost/1" style="text-decoration: none;">
    <div class="blog-card">
        <img src="https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg" alt="Blog Image">
        <div class="blog-content">
            <div class="blog-title">The Genius of Einstein: Exploring IQ and Beyond</div>
            <div class="blog-meta">IQ Spark · May 5, 2024 · Blog · No Comments</div>
            <div class="blog-description">
                Few names evoke the concept of genius as powerfully as Albert Einstein. Renowned for his groundbreaking theories of relativity and [...]
            </div>
        </div>
    </div>
    </a>
  </section>
  <Footers />
</template>
<script>
  import Webheader from '../components/Webheader.vue';
  import Footers from '../components/Footer.vue';
  export default {
    name: 'App',

    // Register the component
    components: {
      Webheader,
      Footers
    },
  };
</script>
<style scoped>
    .blog-card {
            width: 300px;
            border: 1px solid #ddd;
            border-radius: 8px;
            overflow: hidden;
            font-family: Arial, sans-serif;
        }

        .blog-card img {
            width: 100%;
            height: 180px;
            object-fit: cover;
            display: block;
        }

        .blog-content {
            padding: 16px;
            background-color: #f8f9fa;
        }

        .blog-title {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 8px;
            color: #000;
        }

        .blog-meta {
            font-size: 12px;
            color: #888;
            margin-bottom: 8px;
        }

        .blog-description {
            font-size: 14px;
            color: #555;
        }
        @media screen and (max-width:550px){
            .top-blog{
              margin-top: 20% !important;
            }
        }
</style>
<template>
    <Webheader />
    <section class="terms-and-service-body">
        <div class="container">
            <p class="uppercase">Last updated: 28 March 2023</p>
            <h1>Terms of Service</h1>
            <div class="terms-and-service-inner">
                <div class="row main-content" data-spy="scroll" data-target="#list-example" data-offset="20">
                    <div class="col-md-4 sidebar" style="">
                        <div class="terms-and-service-sidebar sidebar__inner" id="list-example"
                            style="position: relative;">
                            <h6 class="content-table-title dm-sans"><img
                                    src="https://iqtester.org/assets/images/auth-and-utility/textalign-left.svg"
                                    alt="icon"> TABLE OF
                                CONTENTS</h6>
                            <ol>
                                <li><a href="#one" class="list-group-item list-group-item-action">Use of Service</a>
                                </li>
                                <li><a href="#two" class="list-group-item list-group-item-action">Your account</a></li>
                                <li><a href="#three" class="list-group-item list-group-item-action">Payment and
                                        Subscription</a></li>
                                <li><a href="#four" class="list-group-item list-group-item-action">Intellectual
                                        Property</a></li>
                                <li><a href="#five" class="list-group-item list-group-item-action">User Content</a></li>
                                <li><a href="#six" class="list-group-item list-group-item-action">Limitation of
                                        Liability</a></li>
                                <li><a href="#sevven" class="list-group-item list-group-item-action">Termination</a>
                                </li>
                                <li><a href="#eight" class="list-group-item list-group-item-action">Governing Law</a>
                                </li>
                                <li><a href="#nine" class="list-group-item list-group-item-action">Changes to Terms</a>
                                </li>
                                <li><a href="#ten" class="list-group-item list-group-item-action">Contact Us</a></li>
                            </ol>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="terms-and-service-content scrollspy-example" data-bs-spy="scroll"
                            data-bs-target="#list-example" data-bs-offset="0" tabindex="0">
                            <p>
                                Welcome to IQTester.org ("<b>us</b>", "<b>we</b>", or "<b>our</b>"). These Terms of
                                Service ("<b>Terms</b>")
                                govern your use of our
                                website (the "<b>Service</b>") and any related products or services provided by us. By
                                using
                                the Service, you
                                agree to be bound by these Terms. Some parts of the Service are billed on a subscription
                                basis
                                ("<b>Subscription(s)</b>"). You will be billed in advance on a recurring subscription
                                that you
                                choose.
                            </p>
                            <p>
                                By accessing or using the Service you agree to be bound by these Terms. If you disagree
                                with any part of the
                                terms then you may not access the Service.
                            </p>

                            <div id="one">
                                <h4>1. Use of Service</h4>
                                <p>
                                    You are granted a non-exclusive, non-transferable, revocable license to use the
                                    Service
                                    for your personal or
                                    commercial use. You agree to use the Service only for lawful purposes and in a way
                                    that
                                    does not infringe
                                    on the rights of others. looked up one of the more obscure Latin words, consectetur,
                                    from a Lorem Ipsum
                                    passage, and going through the cites of the word in classical literature, discovered
                                    the
                                    undoubtable source.
                                    Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                                </p>
                            </div>

                            <div id="two">
                                <h4>2. Your account</h4>
                                <p>
                                    Our Service allows you to post, link, store, share and otherwise make available
                                    certain
                                    information, text,
                                    graphics, videos, or other material ("Content"). You are responsible for the Content
                                    that you post on or
                                    through the Service, including its legality, reliability, and appropriatenes.
                                </p>
                                <p>
                                    Some parts of the Service are billed on a subscription basis. You will be billed in
                                    advance on a recurring
                                    subscription that you choose.
                                </p>

                            </div>
                            <div id="three">
                                <h4>3. Payment and subscription</h4>
                                <p>
                                    Some parts of the Service may require payment before access is granted. If you
                                    choose to
                                    subscribe to
                                    any of our paid services, you agree to pay all fees associated with the
                                    subscription.
                                    Payment may be
                                    made through a third-party payment processor, and you agree to provide accurate
                                    payment
                                    information.
                                </p>
                                <p>
                                    <strong>Taxes:</strong> If you wish to purchase any product or service made
                                    available
                                    through the Service ("<b>Purchase</b>"), you may
                                    be asked to supply certain information relevant to your Purchase including, without
                                    limitation, your name,
                                    address, and payment information.
                                </p>
                                <p>
                                    <strong>Charges:</strong> Our Service allows you to post, link, store, share and
                                    otherwise make available certain information,
                                    text, graphics, videos, or other material.
                                </p>
                            </div>

                            <div id="four">
                                <h4>4. Intellectual Property</h4>
                                <p>
                                    All content on the Service, including but not limited to text, graphics, logos,
                                    images,
                                    and software,
                                    is the property of [<b>Your Company</b>] or its licensors and is protected by
                                    copyright and
                                    other intellectual
                                    property laws. You may not copy, reproduce, distribute, or create derivative works
                                    based
                                    on the content
                                    without our prior written consent.
                                </p>

                                <p>
                                    (i) Our Service allows you to post, link, store, share and otherwise make available
                                    certain information, text, graphics, videos, or other material ("<b>Content</b>").
                                    <br>
                                    (ii) You are responsible for the Content that you post on or through the Service,
                                    including its legality, reliability, and appropriateness. <br>
                                    (iii) For any damage or loss caused or alleged to be caused by or in connection with
                                    use
                                    of or reliance on any such content, goods or services available on or through any
                                    such
                                    web sites or services.
                                </p>
                            </div>

                            <div id="five">
                                <h4>5. User content</h4>
                                <p>
                                    You are solely responsible for any content you upload, submit, or otherwise make
                                    available on the Service
                                    ("User Content"). You agree not to post User Content that is illegal, defamatory, or
                                    infringes on the rights
                                    of others. We reserve the right to remove any User Content that violates these
                                    Terms.
                                </p>
                            </div>

                            <div id="six">
                                <h4>6. Limitation of Liability</h4>
                                <p>
                                    In no event shall IQTester.org be liable for any direct, indirect, incidental,
                                    special, or consequential damages
                                    arising out of or in connection with the use of the Service, including but not
                                    limited
                                    to lost profits, loss of
                                    data, or any other damages, whether based on contract, tort, strict liability, or
                                    any
                                    other theory of liability.
                                </p>
                            </div>

                            <div id="seven">
                                <h4>7. Termination</h4>
                                <p>
                                    We reserve the right to terminate or suspend your access to the Service at any time,
                                    with or without cause,
                                    without prior notice or liability.
                                </p>
                            </div>

                            <div id="eight">
                                <h4>8. Governing Law</h4>
                                <p>
                                    These Terms shall be governed by and construed in accordance with the laws of [Your
                                    Country/State], without regard
                                    to its conflict of law provisions. the content, privacy policies, or practices of
                                    any
                                    third-party web sites or
                                    services. You further acknowledge and agree that IQTester.org shall not be
                                    responsible
                                    or liable, directly
                                    or indirectly, for any damage or loss caused or alleged to be caused by or in
                                    connection
                                    with use of or reliance
                                    on any such content, goods or services available on or through any such web sites or
                                    services
                                </p>
                            </div>

                            <div id="nine">
                                <h4>9. Changes to Terms</h4>
                                <p>
                                    We reserve the right, at our sole discretion, to modify or replace these Terms at
                                    any
                                    time. If a revision is
                                    material we will try to provide at least 30 days' notice prior to any new terms
                                    taking
                                    effect. What constitutes
                                    a material change will be determined at our sole discretion.
                                </p>
                            </div>

                            <div id="ten">
                                <h4>10. Contact us</h4>
                                <p>If you have any questions about these Terms, please contact us at [<a
                                        href="contact-us.html">Contact page</a>].
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footers />
</template>
<script>
    import Webheader from '../components/Webheader.vue';
    import Footers from '../components/Footer.vue';
    export default {
        name: 'App',

        // Register the component
        components: {
            Webheader,
            Footers
        },
    };
</script>
<style scoped>
 @font-face {
    font-family: BalooBhai2;
    src: url("../assets/baloo-bhai-2/BalooBhai2-Regular.ttf");
  }

  *{
    font-family: BalooBhai2 !important;
  }
    /*! CSS Used from: https://iqtester.org/assets/css/bootstrap.min.css */
    *,
    ::after,
    ::before {
        box-sizing: border-box;
    }

    h1,
    h4,
    h6 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    h1 {
        font-size: calc(1.375rem + 1.5vw);
    }

    @media (min-width:1200px) {
        h1 {
            font-size: 2.5rem;
        }
    }

    h4 {
        font-size: calc(1.275rem + .3vw);
    }

    @media (min-width:1200px) {
        h4 {
            font-size: 1.5rem;
        }
    }

    h6 {
        font-size: 1rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ol {
        padding-left: 2rem;
    }

    ol {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    b,
    strong {
        font-weight: bolder;
    }

    a {
        color: var(--bs-link-color);
        text-decoration: underline;
    }

    a:hover {
        color: var(--bs-link-hover-color);
    }

    img {
        vertical-align: middle;
    }

    .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width:576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width:768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width:992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width:1200px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (min-width:1400px) {
        .container {
            max-width: 1320px;
        }
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--bs-gutter-y));
        margin-right: calc(-.5 * var(--bs-gutter-x));
        margin-left: calc(-.5 * var(--bs-gutter-x));
    }

    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }

    @media (min-width:768px) {
        .col-md-4 {
            flex: 0 0 auto;
            width: 33.33333333%;
        }

        .col-md-8 {
            flex: 0 0 auto;
            width: 66.66666667%;
        }
    }

    .list-group-item-action {
        width: 100%;
        color: var(--bs-list-group-action-color);
        text-align: inherit;
    }

    .list-group-item-action:focus,
    .list-group-item-action:hover {
        z-index: 1;
        color: var(--bs-list-group-action-hover-color);
        text-decoration: none;
        background-color: var(--bs-list-group-action-hover-bg);
    }

    .list-group-item-action:active {
        color: var(--bs-list-group-action-active-color);
        background-color: var(--bs-list-group-action-active-bg);
    }

    .list-group-item {
        position: relative;
        display: block;
        padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
        color: var(--bs-list-group-color);
        text-decoration: none;
        background-color: var(--bs-list-group-bg);
        border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
    }

    .list-group-item:first-child {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
    }

    .list-group-item:last-child {
        border-bottom-right-radius: inherit;
        border-bottom-left-radius: inherit;
    }

    .list-group-item:disabled {
        color: var(--bs-list-group-disabled-color);
        pointer-events: none;
        background-color: var(--bs-list-group-disabled-bg);
    }

    /*! CSS Used from: https://iqtester.org/assets/css/headline.css */
    *,
    *::after,
    *::before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    html * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a {
        text-decoration: none;
    }

    /*! CSS Used from: https://iqtester.org/assets/css/style.css */
    * {
        margin: 0;
        padding: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .dm-sans {
        font-family: "DM Sans", sans-serif !important;
    }

    h1,
    h4,
    h6 {
        font-family: "Montserrat Alternates", sans-serif;
        font-weight: 700;
        letter-spacing: 0;
        color: #111111;
        line-height: 1.46;
    }

    h1 {
        font-size: 60px;
    }

    h4 {
        font-size: 24px;
    }

    h6 {
        font-size: 15px;
        font-weight: 500;
    }

    .uppercase {
        text-transform: uppercase !important;
    }

    *:focus {
        outline: none;
    }

    img {
        max-width: 100%;
    }

    ::selection {
  background-color: #007bff; /* Use a blue color for the selection background */
  color: #fff; /* Use white for the selected text color */
}

    a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
    }

    a:hover {
        text-decoration: none;
        color: inherit;
    }

    a:focus {
        text-decoration: none;
        color: inherit;
        outline: none;
        border: none;
    }

    @media (min-width: 1400px) {
        .container {
            max-width: 1170px;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .row {
        margin: 0px -15px !important;
    }

    .col-md-4,
    .col-md-8 {
        padding: 0px 15px !important;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .terms-and-service-body {
        padding-top: 111px;
        padding-bottom: 127px;
    }

    .terms-and-service-body h1 {
        margin-top: 11px;
    }

    .terms-and-service-inner {
        margin-top: 112px;
    }

    .terms-and-service-content p {
        margin-bottom: 20px;
    }

    .terms-and-service-content p b {
        color: #111111;
    }

    .terms-and-service-content p strong {
        color: #111111;
    }

    .terms-and-service-content p a {
        color: #0095ff;
    }

    .terms-and-service-content h4 {
        margin-bottom: 7px;
    }

    .content-table-title {
        text-transform: uppercase;
        color: #0095FF;
        margin-bottom: 20px;
    }

    .content-table-title img {
        margin-right: 15px;
    }

    .terms-and-service-sidebar {
        left: inherit !important;
    }

    .terms-and-service-sidebar ol li {
        line-height: 36px;
    }

    .terms-and-service-sidebar ol li a:hover {
        color: #111111;
    }

    .uppercase {
        text-transform: uppercase;
    }

    /*! CSS Used from: https://iqtester.org/assets/css/responsive.css */
    @media screen and (max-width: 1199px) {
        h1 {
            font-size: 54px;
        }
    }

    @media screen and (max-width: 991px) {
        .terms-and-service-body {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .terms-and-service-inner {
            margin-top: 80px;
        }
    }

    @media screen and (max-width: 767px) {
        h1 {
            font-size: 40px;
        }

        h6 {
            font-size: 14px;
            line-height: 26px;
        }

        .terms-and-service-inner .sidebar {
            margin-bottom: 40px;
        }

        .terms-and-service-sidebar {
            position: relative !important;
            top: 0px !important;
            width: 100% !important;
        }

        .terms-and-service-body h1 {
            font-size: 32px;
        }

        .terms-and-service-body {
            padding-top: 60px;
            padding-bottom: 50px;
        }

        .terms-and-service-inner {
            margin-top: 60px;
        }
    }

    @media screen and (max-width: 575px) {
        .container {
            padding: 0px 20px;
        }
    }

    @media screen and (max-width: 375px) {
        h1 {
            font-size: 35px;
            line-height: 50px;
        }
    }
</style>
<template>
    <Webheader />
    
    <div class="mein-box media-test" style="margin-top:10%;margin-bottom: 10%">
        <button style="color: #fff;
                background-color: #faaa00;
                border-radius: 5px;
                height: 15px;
                position: relative;
                left: 0;
                right: 0;
                margin-left: auto;
                display: block;
                margin-bottom: 20px;
                " @click="RefreshFunc()">Restart</button>
        <div v-if="!quizCompleted">
            <!-- Quiz is in progress -->
            <h1 class="header-question">
                Question No: <span>{{ currentQuestion + 1 }}</span>/{{ questions.length }}
            </h1>
            <div v-if="questions.length > 0" class="quiz-question">
                <div class="question-main same-height-que section-question-width">
                    <span>{{ questions[currentQuestion]?.question_text }}</span>
                    <img v-if="questions[currentQuestion]?.question_image"
                        :src="storageUrl + questions[currentQuestion]?.question_image"
                        alt="Question Image" style="max-width: 300px" />
                </div>
                <div class="option-main same-height-que section-question-width-1">
                    <div class="question-title">Please choose an answer:</div>
                    <!-- <div v-for="(option, index) in optionList" :key="index" @click="selectOption(option, index)"
                        class="option" :class="{ active: selectedOption === option }">
                        <span>{{ index + 1 }})</span>
                        <img v-if="isImage(option)" :src="storageUrl + option"
                            alt="Option Image" />
                        <p v-else style="margin-bottom: 0;">{{ option }}</p>
                    </div> -->
                    <!-- <div class="mobile-responsive-options" style="    width: 100%;
                        display: flex;
                        flex-wrap: wrap;"> -->
                        <div v-for="(option, index) in optionList" 
                            :key="index" 
                            @click="selectOption(option, index)" 
                            class="option mobile-responsive" 
                            :class="{ optionimag: selectedOptionIndex === index }">
                            <span>{{ index + 1 }})</span>
                            <img class="optionimag1" v-if="isImage(option)" 
                                :src="storageUrl + option" 
                                alt="Option Image" />
                            <p v-else style="margin-bottom: 0;">{{ option }}</p>
                            <!-- Debugging output -->
                            <!-- <p>Selected Index: {{ selectedOptionIndex }}</p> -->
                        </div>
                    <!-- </div> -->
                </div>
            </div>
            <div v-if="questions.length == 0">
                <p style="text-align: center; font-size: 20px; font-weight: bold;">Uploading...</p>
            </div>
        </div>

        <div v-else>
            <div v-if="handleCheckoutVal">
                <div v-if="payment_process" class="container lastmessage-last" style="margin-top: 10%;">
                    <Webheader />
                    <h2 style="font-size: 45px;">Thank You</h2>
                    <p style="font-size: 14px;" class="disclaimer">An email with your results has been sent to the address you provided. Please check your spam folder if you don't see it in your inbox.</p>
                </div>
                <div v-else class="container payment-responsive">
                    <h2>Access your complete IQ score and official certificate with a one-time payment of just <span>$5.00</span></h2>
                    <form @submit.prevent="handleCheckout">
                        <div id="card-element"></div>
                        <button type="submit">
                            {{ isProcessingPayment ? 'Processing...' : 'Visa / Mastercard' }}
                        </button>
                        <div id="card-errors" role="alert"></div>
                    </form>
                    <p class="disclaimer">*Your email is confidential. We will never share it with third parties</p>
                </div>
            </div>
            <div v-else>
                <div>
                    <div class="container container-media">
                        <h2>Where to send your IQ score and performance report?</h2>
                        <form @submit.prevent="submitForm">
                            <!-- <label for="name">Please enter your Name:</label> -->
                            <input v-model="name" type="text" id="name" placeholder="Your Name" />

                            <!-- <label for="email">Please enter your email:</label> -->
                            <input v-model="email" type="email" id="email" placeholder="Your Email" />

                            <button type="submit">Send</button>
                        </form>
                        <p class="disclaimer" v-if="payment_error" style="color: red;">*{{ payment_error }}</p>
                        <p class="disclaimer">*Your email is confidential. We will never share it with third parties</p>
                    </div>
                </div>
                <h2>Quiz Completed!</h2>
                <p>Your selected answers:</p>
                <ul>
                    <li v-for="(answer, index) in showanswers" :key="index">
                        <span style="font-weight: bold;font-size: 17px"> {{ index + 1 }}) Question: </span>
                        {{ answer.question }} <br>
                        <span style="font-weight: bold;font-size: 17px; margin-left: 20px;"> Selected option: </span>
                        <span v-if="isImage(answer.selected)">
                            <img :src="storageUrl + answer.selected"
                                alt="Selected Option Image" style="width: 30px; margin-left: 5px;" />
                        </span>
                        <span v-else>
                            {{ answer.selected }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <Footers />
</template>

<script>
    import axios from 'axios';
    import { loadStripe } from '@stripe/stripe-js';
    import Webheader from '../components/Webheader.vue';
    import Footers from '../components/Footer.vue';
    const API_BASEURL = process.env.VUE_APP_API_URL;

    export default {
        data() {
            return {
                currentQuestion: 0,
                selectedOption: null,
                selectedOptionIndex: null,
                answers: [],
                showanswers: [],
                questions: [],
                quizCompleted: false,
                name: '',
                email: '',
                resultData: [],
                handleCheckoutVal: false,
                stripe: null,
                card: null,
                payment_process: false,
                payment_error: "",
                id_get:null,
                isProcessingPayment: false,
                isCooldown: false,
                storageUrl: process.env.VUE_APP_STORAGE_URL,
            };
        },
        computed: {
            optionList() {
                const options = this.questions[this.currentQuestion]?.options || {};
                return [
                    options.option_a || options.option_a_image,
                    options.option_b || options.option_b_image,
                    options.option_c || options.option_c_image,
                    options.option_d || options.option_d_image,
                    options.option_e || options.option_e_image,
                    options.option_f || options.option_f_image,
                ].filter(Boolean);
            },
        },
        async mounted() {
            this.fetchQuestions();
            this.loadProgress();
        },
        methods: {
            async initializeStripe() {
                this.stripe = await loadStripe('pk_live_51PiZ7i2M3XYXK3obxRbiOnnWGrXpRFUEbST75Y2TCFuNpSb3JPswDgFhqn7XNtMPmj7mcpZOC9Ag1xK9O52hIldb00GJmXbCE0');
                const elements = this.stripe.elements();
                this.card = elements.create('card', { hidePostalCode: true });
                this.card.mount('#card-element');
            },
            async handleCheckout() {
                this.isProcessingPayment = true;
                const { token, error } = await this.stripe.createToken(this.card);
                if (error) {
                    document.getElementById('card-errors').innerText = error.message;
                    this.isProcessingPayment = false;
                } else {
                    await this.processPayment(token);
                    this.isProcessingPayment = false;
                }
            },
            submitForm() {
                if (!this.name || !this.email || !this.resultData) {
                    this.payment_error = "Please fill all the fields";
                } else {
                    // this.SendData();
                    this.fetchPerc();
                }
            },
            async processPayment(token) {
                try {
                    const formData = new FormData();
                    formData.append('token', token.id);
                    formData.append('amount', "5");
                    formData.append('id', this.id_get);

                    // const response = 
                    await fetch(`${API_BASEURL}process-payment`, {
                        method: 'POST',
                        body: formData,
                    });

                    // const data = await response.json();
                    this.payment_process = true;
                    localStorage.setItem('quizProgress', "");
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            async fetchQuestions() {
                try {
                    const response = await axios.get(`${API_BASEURL}questions`);
                    this.questions = response.data.questions;
                } catch (error) {
                    console.error('Error fetching questions:', error);
                }
            },
            selectOption(option, index) {
                this.selectedOptionIndex = index;
                if (this.isCooldown) {
                    console.log("Please wait before selecting again.");
                    return; // Exit the function if cooldown is active
                }

                // Activate cooldown to prevent immediate re-clicks
                this.isCooldown = true;
                
                // Allow re-click after 2 seconds
                setTimeout(() => {
                    this.isCooldown = false;
                }, 1000); // Set cooldown duration here

                // Proceed with the selection logic
                this.selectedOption = option;
               

                const optionKeys = ["option_a", "option_b", "option_c", "option_d", "option_e", "option_f"];
                this.answers.push({
                    question_id: this.questions[this.currentQuestion]?.id,
                    selected_answer: optionKeys[index]
                });

                this.showanswers.push({
                    question: this.questions[this.currentQuestion]?.question_text,
                    selected: option
                });

                // Additional delay before moving to the next question
                setTimeout(() => {
                    this.saveProgress();
                    this.nextQuestion();
                }, 1000);
            },
            nextQuestion() {
                this.selectedOption = null; // Reset the selected option
                this.selectedOptionIndex = null; // Reset the selected option index
                if (this.currentQuestion < this.questions.length - 1) {
                    this.currentQuestion++;
                } else {
                    this.quizCompleted = true;
                    // this.fetchPerc();
                }
                this.saveProgress();
            },
            async fetchPerc() {
                const data = { attempts: this.answers };
                try {
                    const response = await axios.post(`${API_BASEURL}questions/calculate-percentage`, data);
                    this.resultData = response.data;
                    this.SendData();
                } catch (error) {
                    console.error('Error fetching questions:', error);
                }
            },
            async SendData() {
                const data = {
                    name: this.name,
                    email: this.email,
                    total_questions: this.resultData?.total_questions,
                    attempted_questions: this.resultData?.attempted_questions,
                    correct_attempts: this.resultData?.correct_attempts,
                    percentage: this.resultData?.percentage
                };
                try {
                    const response = await axios.post(`${API_BASEURL}questions/save-user-data`, data);
                    this.id_get = response?.data?.data?.id;
                    this.handleCheckoutVal = true;
                    this.initializeStripe();
                } catch (error) {
                    console.error('Error sending data:', error);
                }
            },
            isImage(option) {
                return typeof option === 'string' && (option.endsWith('.jpg') || option.endsWith('.png') || option.endsWith('.gif'));
            },
            saveProgress() {
                const progressData = {
                    currentQuestion: this.currentQuestion,
                    answers: this.answers,
                    showanswers: this.showanswers,
                    quizCompleted: this.quizCompleted
                };
                localStorage.setItem('quizProgress', JSON.stringify(progressData));
            },
            loadProgress() {
                const savedData = localStorage.getItem('quizProgress');
                if (savedData) {
                    const { currentQuestion, answers, showanswers, quizCompleted } = JSON.parse(savedData);
                    this.currentQuestion = currentQuestion;
                    this.answers = answers;
                    this.showanswers = showanswers;
                    this.quizCompleted = quizCompleted;
                }
            },
            RefreshFunc(){
                localStorage.setItem('quizProgress', "");
                window.location.reload();
            }
        },
        watch: {
            handleCheckoutVal(newVal) {
                if (newVal) {
                    this.initializeStripe();
                }
            },
        },
        components: {
            Webheader,
            Footers
        },
    };
</script>

<style scoped>
    @media screen and (max-width:450px){
        .mobile-responsive-options{
            flex-direction: column;
            flex-wrap: nowrap !important;
        }
        .mobile-responsive-options div{
            max-height: 90px;
        }
        .lastmessage-last{
            width: 80% !important;
        }
        .mobile-responsive{
            flex-direction: column;
        }
        .header-question{
            margin-bottom: 5px !important;
        }
        .section-question-width{
            min-height: 295px !important;
            height: 295px !important;
            width: 87%;
        }
        .section-question-width img{
            max-width: 240px !important;
        }
        .section-question-width-1{
            min-height: 400px !important;
        }
        .option{
            gap: 0 !important;
            height: 105px !important;
        }
    }
    .optionimag1{
        cursor: pointer;
    }
    .option img {
        display: block; /* Prevents bottom space from images */
    }

    @media screen and (min-width:650px){
           .option::after {
            content: '';
            position: absolute; 
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: solid 5px transparent; 
            transition: border-color 0.3s ease;
            z-index: 1;
        }
        .option:hover::after {
            border-color: #faaa00;
            animation: drawBorder 0.5s forwards;
        }
        @keyframes drawBorder {
            0% {
                border-color: transparent; 
                transform: scale(0); 
            }
            50% {
                transform: scaleX(1) scaleY(0);
            }
            75% {
                transform: scaleY(1); 
            }
            100% {
                transform: scale(1); 
            }
        }  
        .optionimag{
            border: solid 2px #faaa00;
        } 
     
    }
       
    @media screen and (max-width:650px) {
        .optionimag{
            border: solid 2px #faaa00;
        }
    }
        /* @media screen and (min-width:650px){
            .optionimag{
                border: solid 5px #faaa00 !important;
            }
        } */
    @media screen and (max-width:650px){
        .payment-responsive{
            width: 80% !important;
        }
    }
    @media screen and (min-width:2500px) {
        .section-question-width{
            min-width: 45%;
        }
        .section-question-width-1{
            min-width: 45%;
        }
    }
     .section-question-width{
        min-height: 490px;
     }
     .section-question-width-1{
        min-height: 490px;
        max-width: 400px;
     }
     @media screen and (max-width:500px){
        .media-test{
            margin-top: 50px !important;
        }
        .container-media{
            padding : 0 !important; 
        }
     }
     @font-face {
        font-family: BalooBhai2;
        src: url("../assets/baloo-bhai-2/BalooBhai2-Regular.ttf");
      }

      *{
        font-family: BalooBhai2 !important;
      }
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        #card-element {
            border: 1px solid #ccc;
            padding: 10px;
            margin-bottom: 20px;
        }

        .container {
            width: 100%;
            /* max-width: 55%; */
            background: #ffffff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            margin-bottom: 5%;
            text-align: center;
            padding: 35px;
        }

        .container form {
            display: flex;
            flex-direction: column;
            width: 80%;
            margin: 0 auto;
            margin-top: 5%;
            margin-bottom: 5%;
        }

        .container input {
            padding: 10px;
            margin-bottom: 20px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 13px;
            color: #000;
        }

        .container button {
            background-color: #faaa00 !important;
            color: white !important;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s;
            font-size: 15px;
        }

        .container button:hover {
            background-color: #e69500;
        }

        s .container label {
            margin-bottom: 5px;
            font-weight: bold;
        }

        .disclaimer {
            font-size: 12px;
            color: #666;
        }


        .mein-box {
            width: 70%;
            display: block;
            margin: 0 auto;
            margin-top: 5%;
        }


        @media screen and (max-width:600px){
            .mein-box{
                width: 95%;
            display: block;
            margin: 0 auto;
            }
        }
        .header-question {
            background-color: #094065;
            border-radius: 15px;
            display: flex;
            align-items: center;
            padding: 15px 35px;
            margin: 0 0 35px;
            color: #fff;
        }

        .active {
            background-color: lightblue !important;
        }

        /*! CSS Used from: https://iqspark.org/wp-content/themes/astra/assets/css/minified/main.min.css?ver=4.8.1 ; media=all */
        @media all {

            h1,
            h2,
            h4,
            h5,
            li,
            p,
            ul {
                border: 0;
                font-size: 100%;
                font-style: inherit;
                font-weight: inherit;
                margin: 0;
                outline: 0;
                padding: 0;
                vertical-align: baseline;
            }

            img {
                border: 0;
            }

            button,
            input {
                color: inherit;
                font: inherit;
                margin: 0;
            }

            button {
                overflow: visible;
            }

            button {
                text-transform: none;
            }

            button {
                -webkit-appearance: button;
                cursor: pointer;
            }

            input {
                line-height: normal;
            }

            input[type=radio] {
                box-sizing: border-box;
                padding: 0;
            }

            h1,
            h2,
            h4,
            h5 {
                clear: both;
            }

            .entry-content h1,
            h1 {
                color: #808285;
                font-size: 2em;
                line-height: 1.2;
            }

            .entry-content h2,
            h2 {
                color: #808285;
                font-size: 1.7em;
                line-height: 1.3;
            }

            .entry-content h4,
            h4 {
                color: #808285;
                line-height: 1.5;
                font-size: 1.3em;
            }

            .entry-content h5,
            h5 {
                color: #808285;
                line-height: 1.6;
                font-size: 1.2em;
            }

            *,
            :after,
            :before {
                box-sizing: inherit;
            }

            ul {
                margin: 0 0 1.5em 3em;
            }

            ul {
                list-style: disc;
            }

            img {
                height: auto;
                max-width: 100%;
            }

            button,
            input {
                color: #808285;
                font-weight: 400;
                font-size: 100%;
                margin: 0;
                vertical-align: baseline;
            }

            button,
            input {
                line-height: normal;
            }

            button {
                border: 1px solid;
                border-color: var(--ast-border-color);
                border-radius: 2px;
                background: #e6e6e6;
                padding: .6em 1em .4em;
                color: #fff;
            }

            button:focus,
            button:hover {
                color: #fff;
                border-color: var(--ast-border-color);
            }

            button:active,
            button:focus {
                border-color: var(--ast-border-color);
                outline: 0;
            }

            ::selection {
      background-color: #007bff; /* Use a blue color for the selection background */
      color: #fff; /* Use white for the selected text color */
    }

            img {
                vertical-align: middle;
            }

            .entry-content h1,
            .entry-content h2,
            .entry-content h4,
            .entry-content h5 {
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 1.75em;
            }

            input {
                line-height: 1;
            }

            button {
                line-height: 1.85714285714286;
            }

            .entry-content>:last-child {
                margin-bottom: 0;
            }

            .entry-content {
                word-wrap: break-word;
            }

            .entry-content p {
                margin-bottom: 1.6em;
            }

            .entry-content::after {
                display: table;
                clear: both;
                content: '';
            }
        }

        /*! CSS Used from: Embedded */
        button,
        input {
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
            font-weight: 400;
            font-size: 16px;
            font-size: 1rem;
            line-height: var(--ast-body-line-height, 1.65em);
        }

        h1,
        .entry-content h1,
        h2,
        .entry-content h2,
        h4,
        .entry-content h4,
        h5,
        .entry-content h5 {
            font-weight: 600;
        }

        h1,
        .entry-content h1 {
            font-size: 36px;
            font-size: 2.25rem;
            font-weight: 600;
            line-height: 1.4em;
        }

        h2,
        .entry-content h2 {
            font-size: 30px;
            font-size: 1.875rem;
            font-weight: 600;
            line-height: 1.3em;
        }

        h4,
        .entry-content h4 {
            font-size: 20px;
            font-size: 1.25rem;
            line-height: 1.2em;
            font-weight: 600;
        }

        h5,
        .entry-content h5 {
            font-size: 16px;
            font-size: 1rem;
            line-height: 1.2em;
            font-weight: 600;
        }

        ::selection {
      background-color: #007bff; /* Use a blue color for the selection background */
      color: #fff; /* Use white for the selected text color */
    }

        h1,
        .entry-content h1,
        h2,
        .entry-content h2,
        h4,
        .entry-content h4,
        h5,
        .entry-content h5 {
            color: var(--ast-global-color-3);
        }

        input:focus {
            border-color: var(--ast-global-color-0);
        }

        input[type="radio"]:checked {
            border-color: var(--ast-global-color-0);
            background-color: var(--ast-global-color-0);
            box-shadow: none;
        }

        input:focus {
            border-style: dotted;
            border-color: inherit;
            border-width: thin;
        }

        input {
            outline: none;
        }

        button {
            border-radius: 4px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        }

        ::placeholder {
            color: var(--ast-form-field-color, #9CA3AF);
        }

        button {
            border-style: solid;
            border-top-width: 0;
            border-right-width: 0;
            border-left-width: 0;
            border-bottom-width: 0;
            color: #ffffff;
            border-color: var(--ast-global-color-0);
            background-color: var(--ast-global-color-0);
            padding-top: 15px;
            padding-right: 30px;
            padding-bottom: 15px;
            padding-left: 30px;
            font-family: inherit;
            font-weight: 500;
            font-size: 16px;
            font-size: 1rem;
            line-height: 1em;
        }

        button:focus,
        button:hover {
            color: #ffffff;
            background-color: var(--ast-global-color-1);
            border-color: var(--ast-global-color-1);
        }

        @media (max-width:921px) {
            button {
                padding-top: 14px;
                padding-right: 28px;
                padding-bottom: 14px;
                padding-left: 28px;
            }
        }

        @media (max-width:544px) {
            button {
                padding-top: 12px;
                padding-right: 24px;
                padding-bottom: 12px;
                padding-left: 24px;
            }
        }

        @media (max-width:921px) {

            h1,
            .entry-content h1 {
                font-size: 30px;
            }

            h2,
            .entry-content h2 {
                font-size: 25px;
            }
        }

        @media (max-width:544px) {

            h1,
            .entry-content h1 {
                font-size: 30px;
            }

            h2,
            .entry-content h2 {
                font-size: 25px;
            }
        }

        .entry-content[data-ast-blocks-layout]>* {
            max-width: var(--wp--custom--ast-content-width-size);
            margin-left: auto;
            margin-right: auto;
        }

        .ast-page-builder-template .entry-content[data-ast-blocks-layout]>* {
            max-width: none;
        }

        .ast-page-builder-template .entry-content[data-ast-blocks-layout]>*>* {
            max-width: var(--wp--custom--ast-content-width-size);
            margin-left: auto;
            margin-right: auto;
        }

        .entry-content ul {
            padding: revert;
            margin: revert;
            padding-left: 20px;
        }

        h1,
        .entry-content h1,
        h2,
        .entry-content h2,
        h4,
        .entry-content h4,
        h5,
        .entry-content h5 {
            color: var(--ast-global-color-2);
        }

        /*! CSS Used from: https://iqspark.org/wp-includes/css/dist/block-library/style.min.css?ver=6.3.5 ; media=all */
        @media all {
            ul {
                box-sizing: border-box;
            }

            .entry-content {
                counter-reset: footnotes;
            }
        }

        /*! CSS Used from: https://iqspark.org/wp-content/plugins/iq-spark-quiz//frontend/assets/css/iq-spark-quiz-frontend.css?ver=6.3.5 ; media=all */
        @media all {
            .iq-spark-quiz-container {
                width: 95%;
                max-width: 1140px !important;
            }

            .iq-header {
                background-color: #094065;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 35px;
                margin: 0 0 35px;
            }

            .iq-header h1 {
                font-family: 'montserratbold';
                font-weight: bolder;
                font-size: 22px;
                color: #fff;
                text-align: left;
                margin: 0;
            }

            .same-height-que {
                height: 440px;
            }

            .question-main {
                border: 1px solid #b0b0b0;
                border-radius: 15px;
                box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, .12);
                background-color: #fff;
                padding: 25px 35px;
                margin-right: 15px;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            .question-main img {
                display: block;
                margin-left: 0;
                height: auto;
                max-width: 300px;
                margin: 0 auto;
            }

            .same-height-que {
                height: 440px;
            }

            .option-main {
                border: 1px solid #b0b0b0;
                border-radius: 15px;
                box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, .12);
                background-color: #fff;
                padding: 25px 35px;
                flex: 0 0 40%;
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                max-height: 485px;
                overflow-y: scroll;
            }

            .option {
                font-family: 'montserratbold' !important;
                font-weight: bolder !important;
                font-size: 26px !important;
                color: #3f403f !important;
                flex: 0 0 calc(50% - 30px) !important;
                padding: 10px !important;
                background-color: transparent;
                transition: all .5s !important;
                position: relative !important;
                display: flex !important;
                align-items: center !important;
                justify-content: flex-start !important;
                margin-right: 8px !important;
                margin-bottom: 15px !important;
                gap: 10px;
            }

            .option input[type=radio] {
                position: absolute;
                width: 100%;
                height: 100%;
                appearance: none;
                opacity: 0;
                background-color: transparent;
                left: 0;
                top: 0;
                z-index: 1;
                cursor: pointer;
            }

            .option img,
            .option span {
                z-index: 0;
                position: relative;
                pointer-events: none;
            }

            .question-title,
            .question-main span {
                border-bottom: 1px solid #094065;
                font-family: 'montserratbold';
                font-weight: bolder;
                text-align: left;
                padding-bottom: 10px;
                margin-bottom: 15px;
                font-size: 24px;
                line-height: 30px;
                color: #094065;
                display: flex;
                width: 100%;
            }

            .option-main .option {
                font-family: 'montserratbold';
                font-weight: bolder;
                font-size: 26px;
                color: #3f403f;
                -ms-flex: 0 0 calc(50% - 30px);
                flex: 0 0 calc(50% - 30px);
                padding: 10px;
                background-color: transparent;
                transition: all .5s;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-right: 30px;
                margin-bottom: 15px;
                width: 100%;
                max-width: 156px;
                height: 94px;
            }

            .option span,
            .option img {
                z-index: 1;
                position: relative;
                pointer-events: none;
                max-width: 70px !important;
                height: auto;
            }

            .option span {
                margin-right: 5px;
            }

            .option input[type=radio] {
                position: absolute;
                width: 100%;
                max-width: 156px;
                appearance: none;
                opacity: 0;
                background-color: transparent;
                left: 0;
                top: 0;
                z-index: 0;
                cursor: pointer;
            }

            .option img {
                margin-right: 20px;
            }

            .quiz-question {
                display: flex;
                justify-content: center;
            }

            .iq-spark-payments {
                width: 100% !important;
                max-width: 1140px !important;
            }

            .iq-quiz-congratulation-msg {
                font-size: 17px !important;
            }

            .iq-spark-stipe-payment,
            .iq-spark-paypal-payment {
                width: 100%;
                max-width: 50%;
                border: 1px solid #b0b0b0;
                border-radius: 15px;
                box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, .12);
                background-color: #fff;
                padding: 25px 35px;
                margin-right: 15px;
            }

            .sona-payments-title {
                display: none;
            }

            .iq-spark-stipe-payment h2,
            .iq-spark-paypal-payment h2 {
                font-size: 20px;
            }

            .pricing-card-list {
                margin-bottom: 27px;
            }

            .pricing-card-list {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            .pricing-card-list li {
                padding-left: 16px;
                position: relative;
                font-size: 14px;
                padding-bottom: 10px;
            }

            .pricing-card-list li::before {
                position: absolute;
                content: "";
                top: 30%;
                left: 0;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #00cec966;
            }

            .pricing-card-title {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                gap: 10px;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
            }

            .pricing-card-title h2 {
                font-size: 30px;
                color: #111111;
            }

            button.stripe-btn.stripe-btn-rounded.stripe-btn-fill {
                width: 100% !important;
                max-width: 100% !important;
            }

            .sona-payments-product-wrapper-stripe {
                max-width: 100% !important;
            }

            .sona-payments-product .sona-payments-details {
                max-width: 100% !important;
            }

            @media only screen and (max-width: 992px) {
                .iq-spark-payments {
                    flex-direction: column;
                }

                .iq-spark-stipe-payment,
                .iq-spark-paypal-payment {
                    max-width: 100%;
                    margin-bottom: 30px;
                }
            }

            @media only screen and (max-width: 820px) {
                .quiz-question {
                    flex-direction: column;
                }

                .same-height-que {
                    margin-bottom: 20px;
                }
            }

            @media screen and (max-width: 768px) {
                .sona-payments-product .sona-payments-details {
                    margin-top: 0px !important;
                }

                body .iq-spark-payments {
                    width: 100% !important;
                    max-width: 480px !important;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }

            .sona-payments-product-wrapper-stripe .sona-payments-purchase-box .stripe-btn-rounded {
                border-radius: 0;
            }

            body .sona-payments-product-wrapper-stripe .sona-payments-purchase-box .stripe-btn-fill {
                border: 1px solid #202ef0 !important;
                border-radius: 4px !important;
                background: #202ef0 !important;
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }

            body .sona-payments-product-wrapper-stripe .sona-payments-purchase-box .stripe-btn-fill:hover {
                background: #202ef0 !important;
                color: #fff !important;
            }
        }

        /*! CSS Used from: https://iqspark.org/wp-content/plugins/sonawp-simple-payment-block/assets/css/frontend.css?ver=1.3.0 ; media=all */
        @media all {
            .sona-payments-product-wrapper-stripe {
                padding-left: 17px !important;
                padding-right: 17px !important;
            }

            .sona-payments-product-wrapper-stripe .sona-payments-product {
                display: flex;
                justify-content: space-between;
            }

            .sona-payments-product .sona-payments-details {
                width: 100%;
                max-width: 50%;
            }

            .sona-payments-product .sona-payments-details .sona-payments-title h4,
            .sona-payments-product .sona-payments-details h5.sona-payments-currency {
                margin: 0;
                margin-bottom: 15px;
            }

            .sona-payments-product-wrapper-stripe .sona-payments-purchase-box {
                margin-top: 15px;
            }

            .sona-payments-product-wrapper-stripe .sona-payments-purchase-box button {
                background: transparent !important;
                border: 1px solid #ffc439;
                color: #000;
                font-size: 18px;
                line-height: 26px;
                padding: 8px 35px !important;
                text-transform: uppercase;
                border-radius: 0;
                transition: 0.2s all ease-in-out;
                cursor: pointer;
            }

            .sona-payments-product-wrapper-stripe .sona-payments-purchase-box .stripe-btn:hover,
            .sona-payments-product-wrapper-stripe .sona-payments-purchase-box .stripe-btn-rounded:hover {
                background: #ffc439 !important;
            }

            .sona-payments-product-wrapper-stripe .sona-payments-purchase-box .stripe-btn-fill {
                background: #ffc439 !important;
                color: #fff !important;
            }

            .sona-payments-product-wrapper-stripe .sona-payments-purchase-box .stripe-btn-fill:hover {
                background: #fff !important;
                color: #000 !important;
            }

            .sona-payments-product-wrapper-stripe .sona-payments-purchase-box .stripe-btn-rounded {
                border-radius: 50px;
            }

            @media screen and (max-width: 768px) {
                .sona-payments-product {
                    flex-direction: column;
                }

                .sona-payments-product .sona-payments-details {
                    max-width: 100%;
                    margin-top: 30px;
                }
            }
        }

        /*! CSS Used from: https://iqspark.org/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.24.0 ; media=all */
        @media all {

            .elementor *,
            .elementor :after,
            .elementor :before {
                box-sizing: border-box;
            }

            .elementor img {
                height: auto;
                max-width: 100%;
                border: none;
                border-radius: 0;
                box-shadow: none;
            }

            .elementor-element {
                --flex-direction: initial;
                --flex-wrap: initial;
                --justify-content: initial;
                --align-items: initial;
                --align-content: initial;
                --gap: initial;
                --flex-basis: initial;
                --flex-grow: initial;
                --flex-shrink: initial;
                --order: initial;
                --align-self: initial;
                flex-basis: var(--flex-basis);
                flex-grow: var(--flex-grow);
                flex-shrink: var(--flex-shrink);
                order: var(--order);
                align-self: var(--align-self);
            }

            .elementor-element:where(.e-con-full, .elementor-widget) {
                flex-direction: var(--flex-direction);
                flex-wrap: var(--flex-wrap);
                justify-content: var(--justify-content);
                align-items: var(--align-items);
                align-content: var(--align-content);
                gap: var(--gap);
            }

            .elementor-widget {
                position: relative;
            }

            .e-con {
                --border-radius: 0;
                --border-top-width: 0px;
                --border-right-width: 0px;
                --border-bottom-width: 0px;
                --border-left-width: 0px;
                --border-style: initial;
                --border-color: initial;
                --container-widget-width: 100%;
                --container-widget-height: initial;
                --container-widget-flex-grow: 0;
                --container-widget-align-self: initial;
                --content-width: min(100%, var(--container-max-width, 1140px));
                --width: 100%;
                --min-height: initial;
                --height: auto;
                --text-align: initial;
                --margin-top: 0px;
                --margin-right: 0px;
                --margin-bottom: 0px;
                --margin-left: 0px;
                --padding-top: var(--container-default-padding-top, 10px);
                --padding-right: var(--container-default-padding-right, 10px);
                --padding-bottom: var(--container-default-padding-bottom, 10px);
                --padding-left: var(--container-default-padding-left, 10px);
                --position: relative;
                --z-index: revert;
                --overflow: visible;
                --gap: var(--widgets-spacing, 20px);
                --overlay-mix-blend-mode: initial;
                --overlay-opacity: 1;
                --overlay-transition: 0.3s;
                --e-con-grid-template-columns: repeat(3, 1fr);
                --e-con-grid-template-rows: repeat(2, 1fr);
                position: var(--position);
                width: var(--width);
                min-width: 0;
                min-height: var(--min-height);
                height: var(--height);
                border-radius: var(--border-radius);
                z-index: var(--z-index);
                overflow: var(--overflow);
                transition: background var(--background-transition, .3s), border var(--border-transition, .3s), box-shadow var(--border-transition, .3s), transform var(--e-con-transform-transition-duration, .4s);
                --flex-wrap-mobile: wrap;
                margin-block-start: var(--margin-block-start);
                margin-inline-end: var(--margin-inline-end);
                margin-block-end: var(--margin-block-end);
                margin-inline-start: var(--margin-inline-start);
                padding-inline-start: var(--padding-inline-start);
                padding-inline-end: var(--padding-inline-end);
                --margin-block-start: var(--margin-top);
                --margin-block-end: var(--margin-bottom);
                --margin-inline-start: var(--margin-left);
                --margin-inline-end: var(--margin-right);
                --padding-inline-start: var(--padding-left);
                --padding-inline-end: var(--padding-right);
                --padding-block-start: var(--padding-top);
                --padding-block-end: var(--padding-bottom);
                --border-block-start-width: var(--border-top-width);
                --border-block-end-width: var(--border-bottom-width);
                --border-inline-start-width: var(--border-left-width);
                --border-inline-end-width: var(--border-right-width);
            }

            .e-con.e-flex {
                --flex-direction: column;
                --flex-basis: auto;
                --flex-grow: 0;
                --flex-shrink: 1;
                flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
            }

            .e-con>.e-con-inner {
                text-align: var(--text-align);
                padding-block-start: var(--padding-block-start);
                padding-block-end: var(--padding-block-end);
            }

            .e-con.e-flex>.e-con-inner {
                flex-direction: var(--flex-direction);
            }

            .e-con,
            .e-con>.e-con-inner {
                display: var(--display);
            }

            .e-con-boxed.e-flex {
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: normal;
                align-items: normal;
                align-content: normal;
            }

            .e-con-boxed {
                text-align: initial;
                gap: initial;
            }

            .e-con.e-flex>.e-con-inner {
                flex-wrap: var(--flex-wrap);
                justify-content: var(--justify-content);
                align-items: var(--align-items);
                align-content: var(--align-content);
                flex-basis: auto;
                flex-grow: 1;
                flex-shrink: 1;
                align-self: auto;
            }

            .e-con>.e-con-inner {
                gap: var(--gap);
                width: 100%;
                max-width: var(--content-width);
                margin: 0 auto;
                padding-inline-start: 0;
                padding-inline-end: 0;
                height: 100%;
            }

            :is(.elementor-section-wrap, [data-elementor-id])>.e-con {
                --margin-left: auto;
                --margin-right: auto;
                max-width: min(100%, var(--width));
            }

            .e-con .elementor-widget.elementor-widget {
                margin-block-end: 0;
            }

            .e-con:before {
                content: var(--background-overlay);
                display: block;
                position: absolute;
                mix-blend-mode: var(--overlay-mix-blend-mode);
                opacity: var(--overlay-opacity);
                transition: var(--overlay-transition, .3s);
                border-radius: var(--border-radius);
                border-style: var(--border-style);
                border-color: var(--border-color);
                border-block-start-width: var(--border-block-start-width);
                border-inline-end-width: var(--border-inline-end-width);
                border-block-end-width: var(--border-block-end-width);
                border-inline-start-width: var(--border-inline-start-width);
                top: calc(0px - var(--border-top-width));
                left: calc(0px - var(--border-left-width));
                width: max(100% + var(--border-left-width) + var(--border-right-width), 100%);
                height: max(100% + var(--border-top-width) + var(--border-bottom-width), 100%);
            }

            .e-con:before {
                transition: background var(--overlay-transition, .3s), border-radius var(--border-transition, .3s), opacity var(--overlay-transition, .3s);
            }

            .e-con .elementor-widget {
                min-width: 0;
            }

            .e-con>.e-con-inner>.elementor-widget>.elementor-widget-container {
                height: 100%;
            }

            .e-con.e-con>.e-con-inner>.elementor-widget {
                max-width: 100%;
            }

            @media (max-width:767px) {
                .e-con.e-flex {
                    --width: 100%;
                    --flex-wrap: var(--flex-wrap-mobile);
                }
            }

            .elementor-element .elementor-widget-container {
                transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
            }
        }

        /*! CSS Used from: https://iqspark.org/wp-content/plugins/elementor/assets/css/conditionals/e-swiper.min.css?ver=3.24.0 ; media=all */
        @media all {
            .elementor-element {
                --swiper-theme-color: #000;
                --swiper-navigation-size: 44px;
                --swiper-pagination-bullet-size: 6px;
                --swiper-pagination-bullet-horizontal-gap: 6px;
            }
        }

        /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/post-7.css?ver=1722170818 ; media=all */
        @media all {
            .e-con {
                --container-max-width: 1140px;
            }

            .elementor-element {
                --widgets-spacing: 20px 20px;
            }

            @media (max-width:1024px) {
                .e-con {
                    --container-max-width: 1024px;
                }
            }

            @media (max-width:767px) {
                .e-con {
                    --container-max-width: 767px;
                }
            }
        }

        /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/global.css?ver=1722170819 ; media=all */
        @media all {
            .elementor-widget-text-editor {
                color: var(--e-global-color-text);
                font-family: var(--e-global-typography-text-font-family), Sans-serif;
                font-weight: var(--e-global-typography-text-font-weight);
            }
        }

        /*! CSS Used from: https://iqspark.org/wp-content/uploads/elementor/css/post-198.css?ver=1722170886 ; media=all */
        @media all {
            .elementor-198 .elementor-element.elementor-element-17085b13 {
                --display: flex;
                --background-transition: 0.3s;
                --margin-top: 120px;
                --margin-bottom: 0px;
                --margin-left: 0px;
                --margin-right: 0px;
            }

            @media (max-width:767px) {
                .elementor-198 .elementor-element.elementor-element-17085b13 {
                    --margin-top: 70px;
                    --margin-bottom: 0px;
                    --margin-left: 0px;
                    --margin-right: 0px;
                }
            }
        }

        /*! CSS Used from: Embedded */
        .question-main {
            padding: 25px 20px !important;
        }

        @media (max-width:450px) {
            .iq-header {
                margin: 5px 0 35px !important;
            }

            .option-main .option {
                font-family: 'montserratbold';
                font-weight: bolder;
                font-size: 14px;
                color: #3f403f;
                -ms-flex: 0 0 calc(50% - 30px);
                flex: 0 0 calc(50% - 30px);
                background-color: transparent;
                transition: all .5s;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                max-width: 156px;
                height: 94px;
            }

            .option-main {
                padding: 15px 15px !important;
            }
        }

        .iq-header {
            margin: 20px 0 35px !important;
        }
</style>
<template>
    <footer class="footer-section">
        <div class="footer-top">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-3 col-md-6">
                        <div class="footer-card">
                            <div class="footer-info"><a href="/" class="footer-logo"><img
                                        src="../assets/logo2.png"
                                        alt="footer-logo"></a>
                                <p> Iqspark is the dedicated platform for performance management that helps
                                    individuals enhance their cognitive abilities and boost their IQ quickly </p>
                            </div>
                            <div class="footer-follow">
                                <p>Follow:</p>
                                <ul class="social-link">
                                    <li><a href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61559621546203"><img
                                                src="https://iqtester.org/assets/images/icons/facebook.svg"
                                                alt="social-icon"></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6">
                        <div class="row mobile_footer_visa" style="    display: flex;
    justify-content: center;">
                            <div class="col-md-3 col-6">
                                <aside class="footer-widget">
                                    <div class="widget-title">
                                        <h6>Pages</h6>
                                    </div>
                                    <div class="widget-body">
                                        <ul class="widget-list">
                                            <li><a href="/test">Start IQ test</a></li>
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/about">about us</a></li>
                                            <li><a href="/blog">Blog</a></li>
                                            <li><a href="/faq">FAQ</a></li>
                                            <li><a href="/contact">Contact us</a></li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div class="col-md-8 col-6 footer-media" style="display: flex;gap: 10px;align-items: center;">
                                <img style="width:90px" src="../assets/card.png"/>
                                <img style="width:90px" src="../assets/card2.png"/>
                                <img style="width:90px" src="../assets/card3.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 order-md-1 order-2">
                        <div class="footer-copyright">
                            <p class="mb-0">Copyright © 2024 iqspark.org | Powered by iqspark.org</p>
                        </div>
                    </div>
                    <div class="col-md-7 order-md-2 order-1">
                        <ul class="privacy-menu">
                            <li><a href="./termsandcondition">Terms and conditions</a></li>
                            <li><a href="./privacypolicy">Privacy policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div></div>
</template>
<style scoped>
@media screen and (max-width:500px) {
    .mobile_footer_visa{
        flex-direction: column;
    }
    .footer-media{
        width: 100% !important;
        justify-content: center !important;
    }
}
 @font-face {
    font-family: BalooBhai2;
    src: url("../assets/baloo-bhai-2/BalooBhai2-Regular.ttf");
  }

  *{
    font-family: BalooBhai2 !important;
  }
    /*! CSS Used from: https://dev.iqspark.org/css/app.0362521e.css */
    h6 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    @media (min-width:768px) {
        .col-md-3 {
            flex: 0 0 auto;
            width: 25%;
        }

        .col-md-5 {
            flex: 0 0 auto;
            width: 41.66666667%;
        }

        .col-md-6 {
            flex: 0 0 auto;
            width: 50%;
        }

        .col-md-7 {
            flex: 0 0 auto;
            width: 58.33333333%;
        }
    }

    @media (min-width:992px) {
        .col-lg-3 {
            flex: 0 0 auto;
            width: 25%;
        }

        .col-lg-8 {
            flex: 0 0 auto;
            width: 66.66666667%;
        }
    }

    .order-1 {
        order: 1 !important;
    }

    .order-2 {
        order: 2 !important;
    }

    @media (min-width:768px) {
        .order-md-1 {
            order: 1 !important;
        }

        .order-md-2 {
            order: 2 !important;
        }
    }

    h6 {
        font-family: Montserrat Alternates, sans-serif;
        font-weight: 700;
        letter-spacing: 0;
        color: #111;
        line-height: 1.46;
    }

    .col-6,
    .col-lg-3,
    .col-lg-8,
    .col-md-3,
    .col-md-5,
    .col-md-6,
    .col-md-7 {
        padding: 0 15px !important;
    }

    .footer-top .footer-follow p {
        margin-bottom: 0;
    }

    .footer-info {
        margin-bottom: 58px;
    }

    .footer-info .footer-logo {
        margin-bottom: 37px;
        display: block;
    }

    .footer-info p {
        color: #ffffffb2;
    }

    .footer-follow {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .footer-follow p {
        font-family: Montserrat Alternates, sans-serif;
        color: #fff;
    }

    .footer-follow .social-link {
        display: flex;
        align-items: center;
        gap: 28px;
    }

    .footer-follow .social-link li a {
        transition: .3s;
    }

    .footer-follow .social-link li a img {
        width: 16px;
        height: 16px;
        opacity: .7;
        transition: .3s;
    }

    .footer-follow .social-link li a:hover,
    .footer-follow .social-link li a:hover img {
        opacity: 1;
    }

    .privacy-menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 30px;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .privacy-menu li a {
        color: #ffffffb2;
        transition: .3s;
    }

    .privacy-menu li a:hover {
        color: #fff;
    }

    @media screen and (max-width:1199px) {

        .footer-follow,
        .footer-follow .social-link {
            gap: 15px;
        }
    }

    @media screen and (max-width:991px) {
        .footer-card {
            margin-bottom: 40px;
        }
    }

    @media screen and (max-width:767px) {
        .footer-top {
            padding: 80px 0 65px;
        }

        .footer-info {
            margin-bottom: 18px;
        }

        .footer-info .footer-logo {
            margin-bottom: 24px;
        }

        .footer-card {
            margin-bottom: 0;
        }

        .footer-widget .widget-list li a {
            line-height: 36px;
        }

        .footer-bottom {
            padding: 25px 0 20px;
        }

        .footer-widget {
            margin-top: 40px;
        }

        .footer-copyright {
            text-align: center;
        }

        .privacy-menu {
            margin-bottom: 10px;
            justify-content: center;
            gap: 16px;
            flex-wrap: wrap;
        }

        .privacy-menu li,
        .privacy-menu li a {
            line-height: 1;
        }

        .footer-widget .widget-list li:not(:first-child) {
            margin-top: 0;
        }

        .footer-widget .widget-title {
            margin-bottom: 15px;
        }

        h6 {
            font-size: 14px;
            line-height: 26px;
        }

        .footer-widget .widget-list li a {
            padding-bottom: 10px;
            display: block;
        }
    }

    @media screen and (max-width:575px) {
        .footer-info {
            margin-bottom: 40px;
        }

        .footer-info .footer-logo {
            margin-bottom: 30px;
        }

        .container {
            padding: 0 20px;
        }
    }

    h6 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    h6 {
        font-family: Montserrat Alternates, sans-serif;
        font-weight: 700;
        letter-spacing: 0;
        color: #111;
        line-height: 1.46;
    }

    a {
        cursor: pointer;
    }

    a,
    a:focus,
    a:hover {
        text-decoration: none;
        color: inherit;
    }

    a:focus {
        outline: none;
        border: none;
    }

    .col-6,
    .col-lg-3,
    .col-lg-8,
    .col-md-3,
    .col-md-5,
    .col-md-6 {
        padding: 0 15px !important;
    }

    .footer-bottom,
    .footer-top {
        background: #041e35;
    }

    @media screen and (max-width:767px) {
        .footer-top {
            padding: 80px 0 65px;
        }

        .footer-widget .widget-list li a {
            line-height: 36px;
        }

        .footer-bottom {
            padding: 25px 0 20px;
        }

        .footer-widget {
            margin-top: 40px;
        }

        .footer-copyright {
            text-align: center;
        }

        .footer-widget .widget-list li:not(:first-child) {
            margin-top: 0;
        }

        .footer-widget .widget-title {
            margin-bottom: 15px;
        }

        h6 {
            font-size: 14px;
            line-height: 26px;
        }

        .footer-widget .widget-list li a {
            padding-bottom: 10px;
            display: block;
        }
    }

    @media screen and (max-width:575px) {
        .container {
            padding: 0 20px;
        }
    }

    h6 {
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    h6 {
        font-size: 1rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    ul {
        padding-left: 2rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    a {
        color: var(--bs-link-color);
        text-decoration: underline;
    }

    a:hover {
        color: var(--bs-link-hover-color);
    }

    img {
        vertical-align: middle;
    }

    .container {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x)*.5);
        padding-left: calc(var(--bs-gutter-x)*.5);
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width:576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width:768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width:992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width:1200px) {
        .container {
            max-width: 1140px;
        }
    }

    @media (min-width:1400px) {
        .container {
            max-width: 1320px;
        }
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--bs-gutter-y)*-1);
        margin-right: calc(var(--bs-gutter-x)*-.5);
        margin-left: calc(var(--bs-gutter-x)*-.5);
    }

    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x)*.5);
        padding-left: calc(var(--bs-gutter-x)*.5);
        margin-top: var(--bs-gutter-y);
    }

    .col-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    @media (min-width:768px) {
        .col-md-3 {
            flex: 0 0 auto;
            width: 25%;
        }

        .col-md-5 {
            flex: 0 0 auto;
            width: 41.66666667%;
        }

        .col-md-6 {
            flex: 0 0 auto;
            width: 50%;
        }
    }

    @media (min-width:992px) {
        .col-lg-3 {
            flex: 0 0 auto;
            width: 25%;
        }

        .col-lg-8 {
            flex: 0 0 auto;
            width: 66.66666667%;
        }
    }

    .justify-content-between {
        justify-content: space-between !important;
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    html * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    h6 {
        font-family: Montserrat Alternates, sans-serif;
        font-weight: 700;
        letter-spacing: 0;
        color: #111;
        line-height: 1.46;
    }

    h6 {
        font-size: 15px;
        font-weight: 500;
    }

    :focus {
        outline: none;
    }

    img {
        max-width: 100%;
    }

    ::selection {
        background-color: #0095ff;
        color: #fff;
    }

    a {
        cursor: pointer;
    }

    a,
    a:focus,
    a:hover {
        text-decoration: none;
        color: inherit;
    }

    a:focus {
        outline: none;
        border: none;
    }

    @media (min-width:1400px) {
        .container {
            max-width: 1170px;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .row {
        margin: 0 -15px !important;
    }

    .col-6,
    .col-lg-3,
    .col-lg-8,
    .col-md-3,
    .col-md-5,
    .col-md-6 {
        padding: 0 15px !important;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .footer-section {
        background-color: #191c1f;
        position: relative;
        z-index: 0;
    }

    .footer-top {
        padding: 120px 0 70px 0;
    }

    .footer-widget {
        margin-top: 23px;
    }

    .footer-widget .widget-title {
        margin-bottom: 25px;
    }

    .footer-widget .widget-title h6 {
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0;
    }

    .footer-widget .widget-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .footer-widget .widget-list li:not(:first-child) {
        margin-top: 20px;
    }

    .footer-widget .widget-list li a {
        color: #ffffffb2;
        transition: .3s;
    }

    .footer-widget .widget-list li a:hover {
        color: #fff;
    }

    .footer-copyright p {
        color: #444;
        margin-bottom: 0;
    }

    .footer-bottom {
        border-top: 1px solid #d9d9d91a;
        padding: 30px 0;
    }

    .social-link {
        display: flex;
        align-items: center;
        gap: 28px;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .social-link li a {
        transition: .3s;
    }

    .social-link li a img {
        width: 16px;
        height: 16px;
    }

    .social-link li a:hover {
        opacity: .7;
    }

    .footer-copyright p {
        color: #ffffffb2;
    }

    @media screen and (max-width:767px) {
        .footer-top {
            padding: 80px 0 65px;
        }

        .footer-widget .widget-list li a {
            line-height: 36px;
        }

        .footer-bottom {
            padding: 25px 0 20px;
        }

        .footer-widget {
            margin-top: 40px;
        }

        .footer-copyright {
            text-align: center;
        }

        .footer-widget .widget-list li:not(:first-child) {
            margin-top: 0;
        }

        .footer-widget .widget-title {
            margin-bottom: 15px;
        }

        h6 {
            font-size: 14px;
            line-height: 26px;
        }

        .footer-widget .widget-list li a {
            padding-bottom: 10px;
            display: block;
        }
    }

    @media screen and (max-width:575px) {
        .container {
            padding: 0 20px;
        }
    }
</style>